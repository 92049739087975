import axios from 'axios';

export const getProjects = async () => {
  return await axios.get("projects").then((response: any) => response.data).catch((err: any) => err);
}

export const createProject = async (payload: any) => {
  return await axios.post("projects", payload).then((response: any) => response.data).catch((err: any) => err);
}

export const deleteProject = async (projectId: string) => {
  return await axios.delete(`projects/${projectId}`).then((response: any) => response.data).catch((err: any) => err);
}
