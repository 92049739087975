import { useEffect, useState } from "react";
import {
  Separator,
  parseCSV,
} from "@aptitude/aptitudehelpers";

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';

interface DisplayCSVProps {
  data: any
  columnId?: string
  replacements?: any[]
  hightlightRows?: {
    isOneOf: string[]
    column: string
  },
  hightlightColor?: string
}

const DisplayCSV = ({ data, columnId, replacements, hightlightRows, hightlightColor }: DisplayCSVProps) => {
  const [columns, setColumns] = useState<string[]>([]);
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      const { headers, data: rows } = parseCSV(data);
      setColumns(headers);
      setRows(rows);
      checkHighlightedRows(rows);
    }
  }, [data]);

  const [countHighlightedRows, setCountHighlightedRows] = useState(0);
  const checkHighlightedRows = (rows: any) => {
    if (hightlightRows && hightlightRows.isOneOf && hightlightRows.column) {
      const count = rows.filter((row: any) => hightlightRows.isOneOf.includes(row[hightlightRows.column])).length;
      setCountHighlightedRows(count);
    }
  };

  const gridStyle: any = { minHeight: "70vh" };

  // Since the columns are dynamic, we need to create the columns based on the headers

  const _formatColumns = () => {
    const gridColumns: any[] = [];

    columns.map((column: any) => {
      let cellContent: any = {
        name: column,
        header: column,
        defaultFlex: 1,
        minWidth: 200,
        defaultWidth: 150,
      };

      // if this column is in the replacements list, replace this value with the replacement value

      replacements && replacements.length > 0 && replacements.map((replacement: any) => {
        if (replacement.column.toLowerCase() === column.toLowerCase()) {
          cellContent.render = (value: any) => {
            return replacement.component(value.data[column])
          };
        }
      });

      // Highlight the row if the value of the column is in the hightlightColumnValues list

      if (hightlightRows && hightlightRows.column && hightlightRows.isOneOf && hightlightRows.isOneOf.length > 0) {
        cellContent.onRender = (cellProps: any, {data}: any) => {
          if ((hightlightRows.isOneOf).includes(data[hightlightRows.column])) {
            cellProps.style.background = hightlightColor || '#FF000040';
          }
        };
      }

      gridColumns.push(cellContent);
    });

    return gridColumns;
  };

  return <>
    {data && (columns && columns.length > 0) && (rows && rows.length > 0) && <>
      <small className="text-muted">
        {rows.length} row{rows.length > 1 && <>s</>}
        {countHighlightedRows > 0 && <> ({countHighlightedRows || 0} highlighted)</>}
      </small>

      <Separator size={10} />

      <ReactDataGrid
        idProperty={columnId || "id"}
        columns={_formatColumns()}
        dataSource={rows}
        style={gridStyle}
        pagination={false}
        // defaultFilterValue={gridFilter}
        // enableSelection
        // onSelectionChange={(selection: any) => {
        //   if (selection.data.length === 0) {
        //     setSelectedRow(null);
        //     return;
        //   }
        //   setSelectedRow(selection.data);
        // }}
      />
    </>}
  </>
};

export default DisplayCSV;