import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { getTemplates, deleteTemplate, createTemplate } from "../../services/templates.service";
import { getMaterials } from "../../services/materials.service";
import {
  Loading,
  Separator,
  AlertContext,
} from "@aptitude/aptitudehelpers/build";
import * as yup from "yup";

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';

const Templates = () => {
  const { setAlert } = useContext(AlertContext);

  const [templates, setTemplates] = useState([] as any[]);
  const [loadingTemplates, setLoadingTemplates] = useState(true);
  const _listTemplates = async () => {
    setLoadingTemplates(true);
    await getTemplates().then((response: any) => {
      if (response.message === "OK") {
        setTemplates(response.templates);
        setLoadingTemplates(false);
        return;
      }

      setAlert({type: "danger", message: "Error fetching templates"});
      setLoadingTemplates(false);
    })
  };

  const [materials, setMaterials] = useState([] as any[]);
  const [loadingMaterials, setLoadingMaterials] = useState(true);
  const _listMaterials = async () => {
    setLoadingMaterials(true);
    await getMaterials().then((response: any) => {
      if (response.message === "OK") {
        setMaterials(response.materials);
        setLoadingMaterials(false);
        return;
      }

      setAlert({type: "danger", message: "Error fetching materials"});
      setLoadingMaterials(false);
    })
  };

  useEffect(() => {
    _listTemplates();
    _listMaterials();
  }, []);

  const [loadingDeleteTemplate, setLoadingDeleteTemplate] = useState(false);
  const _deleteTemplate = async (id: any) => {
    setLoadingDeleteTemplate(true);
    await deleteTemplate(id).then((response: any) => {
      if (response.message === "OK") {
        templates.splice(templates.findIndex((item: any) => item.id === id), 1);
        setTemplates([...templates]);
        setSelectedRow(null);
        setAlert({type: "success", message: "Template removed"});
        setLoadingDeleteTemplate(false);
        return;
      }

      setAlert({type: "danger", message: "Error"});
      setLoadingDeleteTemplate(false);
    }).catch((err: any) => {
      setAlert({type: "danger", message: "Error"});
      setLoadingDeleteTemplate(false);
    });
  };

  const defaultTemplate: any = {
    field: "",
    fieldValue: "",
    fields: [
      {
        name: "",
        label: "",
        placeholder: "",
      }
    ],
  };
  const [loadingCreateTemplate, setLoadingCreateTemplate] = useState(false);
  const formTemplate: any = useFormik({
    initialValues: defaultTemplate,
    isInitialValid: false,
    validationSchema: yup.object({
      field: yup.string().required("Field is required"),
      fieldValue: yup.string().required("Field value is required"),
      fields: yup.array().of(
        yup.object().shape({
          name: yup.string().required("Name is required"),
          label: yup.string().required("Label is required"),
          placeholder: yup.string().required("Placeholder is required"),
        })
      ),
    }),
    onSubmit: async (values: any) => {
      setLoadingCreateTemplate(true);
      await createTemplate(values).then((response: any) => {
        if (response.message === "OK") {
          setAlert({type: "success", message: "Template added"});
          templates.push(response.template);
          setTemplates([...templates]);
          formTemplate.resetForm();
          setLoadingCreateTemplate(false);
          return;
        }

        setAlert({type: "danger", message: "Error"});
        setLoadingCreateTemplate(false);
      }).catch((err: any) => {
        setAlert({type: "danger", message: "Error"});
        setLoadingCreateTemplate(false);
      });
    }
  });

  const gridStyle: any = { minHeight: "70vh" };

  const gridColumns: any = [
    { name: "id", header: "ID", maxWidth: 190, defaultFlex: 2, },
    { name: 'field', header: "Field", maxWidth: 140, defaultFlex: 2, },
    {
      name: 'fieldValue', header: "Field Value", maxWidth: 190, defaultFlex: 2,
      render: (row: any) => {
        if (row.data.field === "status") {
          return row.data.fieldValue;
        }

        if (row.data.field === "type") {
          const item: any = materials.length > 0 && materials.find((item: any) => item.id === row.data.fieldValue);
          if (item) {
            return `${item.code} - ${item.name}`;
          }

          return `All values (${row.data.fieldValue})`;
        }

        return "-";
      },
    },
    {
      name: 'fields', header: "Fields", minWidth: 100, defaultFlex: 2,
      render: (row: any) => {
        return JSON.stringify(row.data.fields);
        // return row.data.fields.map((field: any, index: number) => {
        //   return <div key={`template-field-${index}`}>
        //     <small>{field.name} - {field.label} - {field.placeholder}</small>
        //   </div>
        // });
      },
    }
  ];

  const gridFilter: any = [
    { name: "id", operator: "startsWith", type: "string", value: "", },
    { name: "field", operator: "startsWith", type: "string", value: "", },
    { name: "fieldValue", operator: "startsWith", type: "string", value: "", },
    { name: "name", operator: "startsWith", type: "string", value: "", },
    { name: "label", operator: "startsWith", type: "string", value: "", },
    { name: "placeholder", operator: "startsWith", type: "string", value: "", },
  ];

  const [selectedRow, setSelectedRow] = useState(null as any);

  return <>
    <h1>Templates</h1>

    <Separator size={20} />

    <div className="row">
      <div className="col-3">

        <div className="card border-0 bg-light">
          <div className="card-body">

            <strong>Add Template</strong>

            <Separator size={20} />

            <form onSubmit={formTemplate.handleSubmit}>

              <div className="row">
                <div className="col-6">
                  When
                  <div className="form-group">
                    <select
                      name="field"
                      onChange={formTemplate.handleChange}
                      value={formTemplate.values.field}
                      disabled={loadingCreateTemplate || loadingTemplates}
                      className="form-control">
                        <option value="">Select field</option>
                        <option value="status">Status</option>
                        <option value="type">Type</option>
                    </select>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>is equal to</label>
                    <select
                      name="fieldValue"
                      onChange={formTemplate.handleChange}
                      value={formTemplate.values.fieldValue}
                      disabled={loadingCreateTemplate || loadingMaterials || formTemplate.values.field === ""}
                      className="form-control">
                        <option value="">Select a value</option>
                        <option value="any_value">Any value</option>
                        {formTemplate.values.field === "status" && <>
                          <option value="current">current</option>
                          <option value="retired">retired</option>
                          <option value="developing">developing</option>
                          <option value="alternative">alternative</option>
                        </>}
                        {formTemplate.values.field === "type" && materials.length > 0 && materials.map((item: any, indexFieldValue: number) => {
                          return <option key={`template-material-fieldValue-${indexFieldValue}`} value={item.id}>{item.code} - {item.name}</option>
                        })}
                    </select>

                  </div>
                </div>
              </div>

              <Separator size={20} />

              Show these fields:

              <Separator size={10} />

              {formTemplate.values.fields.map((field: any, index: number) => {
                return <div key={`template-field-${index}`} className="row mb-2">
                  <div className="col-12 mb-1">
                    <small className="text-muted">Group Field {index+1}:</small>
                    <div className="form-group ps-3">
                      <input
                        type="text"
                        name={`fields[${index}].name`}
                        onChange={formTemplate.handleChange}
                        value={field.name}
                        disabled={loadingCreateTemplate}
                        placeholder="Name"
                        className="form-control" />
                    </div>
                  </div>
                  <div className="col-12 mb-1">
                    <div className="form-group ps-3">
                      <input
                        type="text"
                        name={`fields[${index}].label`}
                        onChange={formTemplate.handleChange}
                        value={field.label}
                        disabled={loadingCreateTemplate}
                        placeholder="Label"
                        className="form-control" />
                    </div>
                  </div>
                  <div className="col-12 mb-1">
                    <div className="form-group ps-3">
                      <input
                        type="text"
                        name={`fields[${index}].placeholder`}
                        onChange={formTemplate.handleChange}
                        value={field.placeholder}
                        disabled={loadingCreateTemplate}
                        placeholder="Placeholder"
                        className="form-control" />
                    </div>
                  </div>
                </div>
              })}

              <Separator size={10} />

              <button
                type="button"
                onClick={() => formTemplate.setFieldValue("fields", [...formTemplate.values.fields, {name: "", label: "", placeholder: ""}])}
                className="btn btn-outline-primary btn-sm">
                  <i className="fas fa-plus-circle me-2"></i> Add Field
              </button>

              <Separator size={20} />

              <button
                type="submit"
                disabled={!formTemplate.isValid || loadingCreateTemplate || loadingTemplates}
                className="btn btn-primary">
                  <Loading loading={loadingCreateTemplate} parent="inline" color="text-white" />
                  {!loadingCreateTemplate && <i className="fas fa-check me-2"></i>} 
                  Add Template
              </button>
            </form>

          </div>
        </div>

      </div>
      <div className="col">

        {!loadingTemplates && templates.length === 0 && <>No templates found</>}

        <div className="row align-items-center">
          <div className="col">
            <small className="text-muted">{templates.length || 0} templates found</small>
          </div>
          <div className="col text-end">
            <button
              type="button"
              disabled={loadingTemplates}
              onClick={() => _listTemplates()}
              className="btn btn-link btn-sm me-3">
                <Loading loading={loadingTemplates} parent="inline" />
                {!loadingTemplates && <i className="fas fa-sync me-2"></i>} 
                refresh
            </button>

            <button
              type="button"
              disabled={!selectedRow || !selectedRow.id || loadingDeleteTemplate}
              onClick={() => _deleteTemplate(selectedRow.id)}
              className="btn btn-outline-primary btn-sm">
                <Loading loading={loadingDeleteTemplate} parent="inline" />
                {!loadingDeleteTemplate && <i className="fas fa-trash me-2"></i>} 
                Remove Selected Template
            </button>
          </div>
        </div>

        <Separator size={10} />

        <Loading loading={loadingTemplates} />

        {!loadingTemplates && templates.length > 0 && <>

          <ReactDataGrid
            idProperty="id"
            columns={gridColumns}
            dataSource={templates}
            style={gridStyle}
            pagination={false}
            defaultFilterValue={gridFilter}
            enableSelection
            onSelectionChange={(selection: any) => {
              if (selection.data.length === 0) {
                setSelectedRow(null);
                return;
              }
              setSelectedRow(selection.data);
            }}
          />
        </>}

      </div>
    </div>
  </>
};

export default Templates;