export const getPartDataById = (parts: any[], id: object | string | any, columnToReturn: string = "") => {

    if (typeof id === "object") {
      id = id.id;
    }

    const part: any = parts.find((item: any) => item.id === id);
    if (!part) {
      return "N/A";
    }

    if (columnToReturn === "") {
      return part;
    }

    if (part[columnToReturn]) {
      return part[columnToReturn];
    }
};

export const getMaterialDataById = (materials: any[], id: string, columnToReturn: string = "") => {
    const material: any = materials.find((item: any) => item.id === id);
    if (!material) {
      return "N/A";
    }

    if (columnToReturn === "") {
      return material;
    }

    if (material[columnToReturn]) {
      return material[columnToReturn];
    }
};

export const convertRawPartsToTree = (parts: any[]) => {
  if (parts && parts.length > 0) {
    parts.map((item: any) => {
      if (item.parents && item.parents.length > 0) {
        item.parents = item.parents.map((parent: any) => {
          const parentPart: any = parts.find((part: any) => part.id === parent);
          return parentPart;
        });
      }
    });

    parts.map((item: any) => {
      if (item.partsOfAssembly && item.partsOfAssembly.length > 0) {
        item.partsOfAssembly = item.partsOfAssembly.map((child: any) => {
          const childPart: any = parts.find((part: any) => part.id === child);
          return childPart;
        });
      }
    });
  }

  return parts;
};
