import axios from 'axios';

export const getDocumentsByPartId = async (partId: string) => {
  return await axios.get(`documents/${partId}`).then((response: any) => response.data).catch((err: any) => err);
}

export const createDocument = async (partId: string, payload: any) => {
  return await axios.post(`documents/${partId}`, payload).then((response: any) => response.data).catch((err: any) => err);
}

export const toggleDocumentAsCurrent = async (partId: string, documentID: string) => {
  return await axios.patch(`documents/${partId}/${documentID}/toggleDocumentAsCurrent`).then((response: any) => response.data).catch((err: any) => err);
}

export const removeDocument = async (partId: string, documentId: string) => {
  return await axios.delete(`documents/${partId}/${documentId}`).then((response: any) => response.data).catch((err: any) => err);
}