import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getMaterialDataById, getPartDataById } from "../../services/helpers.service";
import { createPart, getParts } from "../../services/parts.service";
import { getMaterials } from "../../services/materials.service";
import { getTemplates } from "../../services/templates.service";
import { useFormik } from "formik";
import {
  Loading,
  Separator,
  convertFirebaseTimestampToString,
  AlertContext,
  CustomModal,
} from "@aptitude/aptitudehelpers/build";
import Status from "../../components/Status/Status.component";
import FormUpdateParts from "../../components/FormUpdateParts/FormUpdateParts.component";

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';

const Parts = () => {
  const { setAlert } = useContext(AlertContext);

  const [parts, setParts] = useState([] as any);
  const [loadingParts, setLoadingParts] = useState(false);
  const _listParts = async () => {
    setLoadingParts(true);
    await getParts().then((response: any) => {
      if (response.message === "OK") {
        setParts(response.parts);
        setLoadingParts(false);
        return;
      }

      console.log("Error _listParts", response);
      setAlert({ type: "danger", message: "Error" });
      setLoadingParts(false);
    }).catch((err: any) => {
      console.log("Catch error _listParts", err);
      setAlert({ type: "danger", message: "Error" });
      setLoadingParts(false);
    });
  };

  const [materials, setMaterials] = useState([] as any);
  const [loadingMaterials, setLoadingMaterials] = useState(false);
  const _listMaterials = async () => {
    await getMaterials().then((response: any) => {
      setLoadingMaterials(true);
      if (response.message === "OK") {
        setMaterials(response.materials);
        setLoadingMaterials(false);
        return;
      }

      setAlert({ type: "danger", message: "Error" });
      setLoadingMaterials(false);
    }).catch((err: any) => {
      setAlert({ type: "danger", message: "Error" });
      setLoadingMaterials(false);
    });
  };

  const [templates, setTemplates] = useState([] as any);
  const [, setLoadingTemplates] = useState(false);
  const _listTemplates = async () => {
    setLoadingTemplates(true);
    await getTemplates().then((response: any) => {
      if (response.message === "OK") {
        setTemplates(response.templates);
        setLoadingTemplates(false);
        return;
      }

      setAlert({type: "danger", message: "Error fetching templates"});
      setLoadingTemplates(false);
    })
  };

  useEffect(() => {
    _listParts();
    _listMaterials();
    _listTemplates();
  }, []);

  useEffect(() => {
    if (parts.length > 0) {
      setParts(parts.sort((a: any, b: any) => a.partNumber > b.partNumber ? 1 : -1));
    }
  }, [parts]);

  const [loadingCreatePart, setLoadingCreatePart] = useState(false);

  const _updatePart = (part: any) => {
    const index = parts.findIndex((item: any) => item.id === part.id);
    parts[index] = part;

    // foreach part, if it has parents.length > 0,
    // get the partNumber of each parent and add it to the part.parents array as an object

    if (part.parents && part.parents.length > 0) {
      parts[index].parents = part.parents.map((parent: any) => {
        return getPartDataById(parts, parent);
      });
    }

    // foreach part, if it has partsOfAssembly.length > 0,
    // get the partNumber of each child and add it to the part.partsOfAssembly array as an object

    if (part.partsOfAssembly && part.partsOfAssembly.length > 0) {
      parts[index].partsOfAssembly = part.partsOfAssembly.map((child: any) => {
        return getPartDataById(parts, child);
      });
    }

    delete parts[index].updateAction;
    setParts([...parts]);
  };

  const _deletePart = (partId: string) => {
    const index: any = parts.findIndex((item: any) => item.id === partId);
    parts.splice(index, 1);
    setParts([...parts]);
  };

  const gridStyle: any = { minHeight: "80vh" };

  const gridColumns: any = [
    { name: "id", header: "id", minWidth: 190, defaultFlex: 2, },
    {
      name: "type", header: "Type", minWidth: 170, defaultFlex: 2,
      render: (value: any) => {
        if (!value.data.type || value.data.type === "") {
          return <span className="text-body-tertiary">N/A</span>
        }
        return `${getMaterialDataById(materials, value.data.type, "code")} - ${getMaterialDataById(materials, value.data.type, "name")}`;
      }
    },
    { name: "partNumber", header: "Part Number", minWidth: 240, defaultFlex: 2, },
    {
      name: "description", header: "Description", minWidth: 270, defaultFlex: 2,
      render : (value: any) => {
        if (!value.data.description || value.data.description.length === 0) {
          return <span className="text-body-tertiary">N/A</span>
        }
        return value.data.description.join(", ");
      }
    },
    {
      name: "status", header: "Status", minWidth: 200, defaultFlex: 2,
      render: (value: any) => {
        if (value.data.status === "alternative") {
          return <Status status={value.data.status} text={`&rarr; ${getPartDataById(parts, value.data.alternativeTo, "partNumber")}`} />
        }
        return <Status status={value.data.status} />
      },
    },
    {
      name: "parents", header: "Child Of", minWidth: 170, defaultFlex: 2,
      render: (value: any) => {
        if (!value.data.parents || value.data.parents.length === 0) {
          return <span className="text-body-tertiary">N/A</span>
        }
        const parents: any = [];
        for (let i = 0; i < value.data.parents.length; i++) {
          parents.push(value.data.parents[i].partNumber);
        }
        return parents.join(", ");
      }
    },
    {
      name: "alternativeTo", header: "Alternative To", minWidth: 170, defaultFlex: 2,
      render: (value: any) => {
        if (!value.data.alternativeTo || value.data.alternativeTo === "") {
          return <span className="text-body-tertiary">N/A</span>
        }
        return getPartDataById(parts, value.data.alternativeTo, "partNumber");
      }
    },
    {
      name: "partsOfAssembly", header: "Parts in this assembly", minWidth: 470, defaultFlex: 2,
      render: (value: any) => {
        if (!value.data.partsOfAssembly || value.data.partsOfAssembly.length === 0) {
          return <span className="text-body-tertiary">N/A</span>
        }
        const partsOfAssemblyList: any = [];
        for (let i = 0; i < value.data.partsOfAssembly.length; i++) {
          partsOfAssemblyList.push(value.data.partsOfAssembly[i].partNumber);
        }
        return partsOfAssemblyList.join(", ");
      }
    },
    {
      name: "createdAt", header: "Created At", minWidth: 170, defaultFlex: 2,
      render: (value: any) => {
        return convertFirebaseTimestampToString(value.data.createdAt);
      }
    },
    {
      name: "updatedAt", header: "Updated At", minWidth: 170, defaultFlex: 2,
      render: (value: any) => {
        return convertFirebaseTimestampToString(value.data.updatedAt);
      }
    },
  ];

  const gridFilter: any = [
    { name: "id", operator: "startsWith", type: "string", value: "", },
    { name: "type", operator: "startsWith", type: "string", value: "", },
    { name: "partNumber", operator: "startsWith", type: "string", value: "", },
    { name: "description", operator: "startsWith", type: "string", value: "", },
    { name: "status", operator: "startsWith", type: "string", value: "", },
    { name: "parentId", operator: "startsWith", type: "string", value: "", },
  ];

  const [selectedPart, setSelectedPart] = useState(null as any);

  const [showModal, setShowModal] = useState(false);

  const [newPartExists, setNewPartExists] = useState(null as any);

  const newPartInitialValues: any = {
    material: "",
    increasePartNumberMethod: "",
  };

  const formCreatePart: any = useFormik({
    initialValues: newPartInitialValues,
    isInitialValid: false,
    onSubmit: async (values: any) => {
      if (!newPartExists) {
        values.increasePartNumberMethod = "";
      }

      setLoadingCreatePart(true);
      await createPart({type: values.material, increasePartNumberMethod: values.increasePartNumberMethod || ""}).then((response: any) => {
        if (response.message === "OK") {
          setParts([...parts, response.part]);
          setAlert({ type: "success", message: `Part "${response.part.partNumber}" created` });
          setLoadingCreatePart(false);
          setShowModal(false);
          formCreatePart.resetForm();
          setNewPartExists(null);
          return;
        }

        setAlert({ type: "danger", message: "Error creating part" });
        setLoadingCreatePart(false);
      }).catch((err: any) => {
        setAlert({ type: "danger", message: "Error creating part" });
        setLoadingCreatePart(false);
      });

    }
  });

  useEffect(() => {
    if (formCreatePart.values.material !== "") {
      const partExists: any = parts.filter((part: any) => part.type === formCreatePart.values.material);
      if (partExists.length > 0) {
        setNewPartExists(true);
        return;
      }
      setNewPartExists(false);
    }
  }, [formCreatePart.values.material]);

  return <>
    <div className="row align-items-center">
      <div className="col">
        <h1>Parts Management</h1>
      </div>
      <div className="col text-end">
        {/*
        <Link to="/parts/import" className="btn btn-outline-primary me-3">
          <i className="fas fa-file-csv me-2"></i> Import from CSV
        </Link>
        */}

        <button
          type="button"
          disabled={loadingParts}
          className="btn btn-primary"
          onClick={() => setShowModal(true)}>
            <i className="fas fa-plus-circle me-2"></i> Create new Part
        </button>
      </div>
    </div>

    <Separator size={20} />

    {!loadingParts && parts.length === 0 && <>No parts found</>}

    <div className="row align-items-center">
      <div className="col">
        {!loadingParts && <small className="text-muted">{parts.length} parts found</small>}
      </div>
      <div className="col text-end">
        <button disabled={loadingParts} onClick={() => _listParts()} type="button" className="btn btn-link inline">
          <Loading loading={loadingParts} parent="inline" />
          {!loadingParts && <i className="fas fa-sync me-2"></i>} refresh
        </button>
      </div>
    </div>

    <Loading loading={loadingParts} />

    <Separator size={20} />

    {!loadingParts && parts.length > 0 && <>
      <ReactDataGrid
        idProperty="id"
        columns={gridColumns}
        dataSource={parts}
        style={gridStyle}
        pagination={false}
        defaultFilterValue={gridFilter}
        enableSelection
        onSelectionChange={(selection: any) => {
          if (selection.data.length === 0) {
            setSelectedPart({});
            return;
          }
          setSelectedPart(selection.data);
        }}
      />

      <FormUpdateParts
        parts={parts}
        selectedPart={selectedPart}
        materials={materials}
        templates={templates}
        onSetSelectedPart={(e: any) => setSelectedPart(e)}
        onDeletePart={(e: any) => _deletePart(e)}
        onUpdatePart={(e: any) => _updatePart(e)} />
    </>}

    {/* MODAL CREATE PART */}

    <CustomModal
      show={showModal}
      size="lg"
      title="Create Part">

      <form onSubmit={formCreatePart.handleSubmit}>

        <div className="form-group">
          <label>Select a material</label>
          <select
            onChange={formCreatePart.handleChange}
            name="material"
            disabled={loadingCreatePart}
            value={formCreatePart.values.material}
            className="form-control">
              <option value="">Select a material</option>
              {materials.length > 0 && <>
                {materials.map((material: any) => {
                  return <option key={`create-new-material-select-${material.id}`} value={material.id}>
                    {material.code} - {material.name}
                  </option>
                })}
              </>}
          </select>
        </div>

        <Separator size={20} />

        {newPartExists === false && <>
          <strong>A new part number will be generated for this material.</strong><br />
          You can change it later.
        </>}

        {newPartExists === true && <>
          <strong>A part with this material already exists.</strong><br />
          Please select the method for increasing the part number.

          <Separator size={20} />

          <div className="row">
            <div className="col">

              <div className="card bg-light">
                <div className="card-body">

                  <label className="cursor-pointer">
                    <input
                      type="radio"
                      disabled={loadingCreatePart}
                      onChange={formCreatePart.handleChange}
                      value="increase_partNumber"
                      name="increasePartNumberMethod" /> Increase part number

                    <Separator size={10} />

                    <small className="text-muted">
                      eg: ADA-01-<strong className="text-primary">A0</strong>01 &rarr; ADA-01-<strong className="text-primary">B0</strong>01
                    </small>
                  </label>

                </div>
              </div>

            </div>
            <div className="col">

              <div className="card bg-light">
                <div className="card-body">

                  <label className="cursor-pointer">
                    <input
                      type="radio"
                      disabled={loadingCreatePart}
                      onChange={formCreatePart.handleChange}
                      value="increase_revision"
                      name="increasePartNumberMethod" /> Increase revision number

                    <Separator size={10} />

                    <small className="text-muted">
                      eg: ADA-01-A0<strong className="text-primary">01</strong> &rarr; ADA-01-A0<strong className="text-primary">02</strong>
                    </small>
                  </label>

                </div>
              </div>
            </div>
          </div>

        </>}

        <Separator size={20} />

        <button type="submit" className="btn btn-primary">
          <Loading loading={loadingCreatePart} parent="inline" color="text-white" />
          {!loadingCreatePart && <i className="fas fa-check me-2"></i>} Create
        </button>

        <button type="button" className="btn btn-inline" onClick={() => setShowModal(false)}>Cancel</button>
      </form>
    </CustomModal>
  </>
};

export default Parts;