import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { getMaterials, deleteMaterial, createMaterial } from "../../services/materials.service";
import {
  AlertContext,
  Separator,
  Loading,
} from "@aptitude/aptitudehelpers/build";
import * as yup from "yup";

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';

const Materials = () => {
  const { setAlert } = useContext(AlertContext);
  const [materials, setMaterials] = useState([] as any[]);
  const [loadingMaterials, setLoadingMaterials] = useState(true);

  const listMaterials = async () => {
    setLoadingMaterials(true);
    await getMaterials().then((response: any) => {
      if (response.message === "OK") {
        setMaterials(response.materials);
        setLoadingMaterials(false);
        return;
      }

      setAlert({ type: "danger", message: "Error" });
      setLoadingMaterials(false);
    }).catch((err: any) => {
      setAlert({ type: "danger", message: "Error" });
      setLoadingMaterials(false);
    });
  };

  useEffect(() => {
    listMaterials();
  }, []);

  const [loadingDeleteMaterial, setLoadingDeleteMaterial] = useState(false);
  const _deleteMaterial = async (id: any) => {
    setLoadingDeleteMaterial(true);
    await deleteMaterial(id).then((response: any) => {
      if (response.message === "OK") {
        materials.splice(materials.findIndex((project: any) => project.id === id), 1);
        setMaterials([...materials]);
        setSelectedRow(null);
        setAlert({type: "success", message: "Material removed"});
        setLoadingDeleteMaterial(false);
        return;
      }

      setAlert({type: "danger", message: "Error"});
      setLoadingDeleteMaterial(false);
    }).catch((err: any) => {
      setAlert({type: "danger", message: "Error"});
      setLoadingDeleteMaterial(false);
    });
  };

  const [loadingCreateMaterial, setLoadingCreateMaterial] = useState(false);
  const defaultMaterial: any = {code: "", name: ""};
  const formMaterial: any = useFormik({
    initialValues: defaultMaterial,
    isInitialValid: false,
    validationSchema: yup.object({
      code: yup.string().required("Required").max(3, "Max 3 characters"),
      name: yup.string().required(),
    }),
    onSubmit: async (values) => {
      setLoadingCreateMaterial(true);
      await createMaterial(values).then((response: any) => {
        if (response.message === "OK") {
          materials.push(response.material);
          setMaterials([...materials]);
          formMaterial.resetForm();
          setLoadingCreateMaterial(false);
          setAlert({type: "success", message: "Material added"});
          return;
        }

        setAlert({type: "danger", message: "Error"});
        setLoadingCreateMaterial(false);
      }).catch((err: any) => {
        setAlert({type: "danger", message: "Error"});
        setLoadingCreateMaterial(false);
      });
    }
  });

  const gridStyle: any = { minHeight: "70vh" };

  const gridColumns: any = [
    { name: "id", header: "ID", maxWidth: 190, defaultFlex: 2, },
    { name: "code", header: "Code", maxWidth: 100, defaultFlex: 2, },
    { name: "name", header: "Name", minWidth: 270, defaultFlex: 2, },
  ];

  const gridFilter: any = [
    { name: "id", operator: "startsWith", type: "string", value: "", },
    { name: "code", operator: "startsWith", type: "string", value: "", },
    { name: "name", operator: "startsWith", type: "string", value: "", },
  ];

  const [selectedRow, setSelectedRow] = useState(null as any);

  return <>
    <h1>Materials</h1>

    <Separator size={20} />

    <div className="row">
      <div className="col-3">

        <div className="card border-0 bg-light">
          <div className="card-body">

            <strong>Add Material</strong>

            <Separator size={20} />

            <form onSubmit={formMaterial.handleSubmit}>
              <div className="form-group">
                <label>Material Code</label>
                <input
                  type="text"
                  name="code"
                  placeholder="Code"
                  className="form-control"
                  autoComplete="off"
                  disabled={loadingMaterials || loadingCreateMaterial}
                  value={formMaterial.values.code}
                  onChange={formMaterial.handleChange} />
              </div>

              <Separator size={20} />

              <div className="form-group">
                <label>Material Name</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Material Name"
                  className="form-control"
                  autoComplete="off"
                  disabled={loadingMaterials || loadingCreateMaterial}
                  value={formMaterial.values.name}
                  onChange={formMaterial.handleChange} />
              </div>

              <Separator size={20} />

              <div className="d-grid">
                <button
                  type="submit"
                  disabled={!formMaterial.isValid || loadingMaterials || loadingCreateMaterial}
                  className="btn btn-primary">
                    <Loading loading={loadingCreateMaterial} parent="inline" color="text-white" />
                    {!loadingCreateMaterial && <i className="fas fa-check me-2"></i>} 
                    Add Material
                </button>
              </div>
            </form>

          </div>
        </div>

      </div>
      <div className="col">

        {!loadingMaterials && materials.length === 0 && <>No materials found</>}

        <div className="row align-items-center">
          <div className="col">
            <small className="text-muted">{materials.length || 0} materials found</small>
          </div>
          <div className="col text-end">

            <button
              type="button"
              disabled={loadingMaterials}
              onClick={() => listMaterials()}
              className="btn btn-link btn-sm me-3">
                <Loading loading={loadingMaterials} parent="inline" />
                {!loadingMaterials && <i className="fas fa-sync me-2"></i>} 
                refresh
            </button>

            <button
              type="button"
              disabled={!selectedRow || !selectedRow.id || loadingDeleteMaterial}
              onClick={() => _deleteMaterial(selectedRow.id)}
              className="btn btn-outline-primary btn-sm">
                <Loading loading={loadingDeleteMaterial} parent="inline" />
                {!loadingDeleteMaterial && <i className="fas fa-trash me-2"></i>} 
                Remove Selected Material
            </button>
          </div>
        </div>

        <Separator size={10} />

        <Loading loading={loadingMaterials} />

        {!loadingMaterials && materials.length > 0 && <>

          <ReactDataGrid
            idProperty="id"
            columns={gridColumns}
            dataSource={materials}
            style={gridStyle}
            pagination={false}
            defaultFilterValue={gridFilter}
            enableSelection
            onSelectionChange={(selection: any) => {
              if (selection.data.length === 0) {
                setSelectedRow(null);
                return;
              }
              setSelectedRow(selection.data);
            }}
          />
        </>}

      </div>
    </div>
  </>
};

export default Materials;