import {Separator} from "@aptitude/aptitudehelpers/build";
import { Link, NavLink, useMatch, useResolvedPath } from "react-router-dom";

const CustomNavLink = ({ to, title }: any) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return <>
    <NavLink to={to} className={`nav-link ${match ? 'active' : ''}`} >
      {title}
    </NavLink>
  </>
};

const Navbar = () => {
  return <>
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container">
        <Link to={'/parts'} className="navbar-brand">Inventory</Link>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <CustomNavLink to={'/projects'} title='Projects' />
            </li>
            <li className="nav-item">
              <CustomNavLink to={'/materials'} title='Materials' />
            </li>
            <li className="nav-item">
              <CustomNavLink to={'/templates'} title='Templates' />
            </li>
            <li className="nav-item">
              <CustomNavLink to={'/parts'} title='Parts' />
            </li>
            <li className="nav-item">
              <CustomNavLink to={'/treeview'} title='Tree View' />
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <Separator size={40} />
  </>
};

export default Navbar;