import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { createProject, deleteProject, getProjects } from "../../services/projects.service";
import {
  Loading,
  Separator,
  AlertContext,
} from "@aptitude/aptitudehelpers/build";
import * as yup from "yup";

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';

const Projects = () => {
  const { setAlert } = useContext(AlertContext);
  const [projects, setProjects] = useState([] as any[]);
  const [loadingProjects, setLoadingProjects] = useState(true);

  const listProjects = async () => {
    setLoadingProjects(true);
    await getProjects().then((response: any) => {
      if (response.message === "OK") {
        setProjects(response.projects);
        setLoadingProjects(false);
        return;
      }

      setAlert({ type: "danger", message: "Error" });
      setLoadingProjects(false);
    }).catch((err: any) => {
      setAlert({ type: "danger", message: "Error" });
      setLoadingProjects(false);
    });
  };

  useEffect(() => {
    listProjects();
  }, []);

  const [loadingDeleteProject, setLoadingDeleteProject] = useState(false);
  const _deleteProject = async (id: any) => {
    setLoadingDeleteProject(true);
    await deleteProject(id).then((response: any) => {
      if (response.message === "OK") {
        projects.splice(projects.findIndex((project: any) => project.id === id), 1);
        setProjects([...projects]);
        setSelectedRow(null);
        setAlert({type: "success", message: "Project removed"});
        setLoadingDeleteProject(false);
        return;
      }

      setAlert({type: "danger", message: "Error"});
      setLoadingDeleteProject(false);
    }).catch((err: any) => {
      setAlert({type: "danger", message: "Error"});
      setLoadingDeleteProject(false);
    });
  };

  const [loadingCreateProject, setLoadingCreateProject] = useState(false);
  const defaultProject: any = {code: "", name: ""};
  const formProject: any = useFormik({
    initialValues: defaultProject,
    validationSchema: yup.object({
      code: yup.string().required("Required").max(2, "Max 2 characters"),
      name: yup.string().required("Required"),
    }),
    isInitialValid: false,
    onSubmit: async (values) => {
      setLoadingCreateProject(true);
      await createProject(values).then((response: any) => {
        if (response.message === "OK") {
          projects.push(response.project);
          setProjects([...projects]);
          formProject.resetForm();
          setLoadingCreateProject(false);
          setAlert({type: "success", message: "Project added"});
          return;
        }

        setAlert({type: "danger", message: "Error"});
        setLoadingCreateProject(false);
      }).catch((err: any) => {
        setAlert({type: "danger", message: "Error"});
        setLoadingCreateProject(false);
      });
    }
  });

  const gridStyle: any = { minHeight: "50vh" };

  const gridColumns: any = [
    { name: "id", header: "ID", maxWidth: 190, defaultFlex: 2, },
    { name: "code", header: "Code", maxWidth: 100, defaultFlex: 2, },
    { name: "name", header: "Name", minWidth: 270, defaultFlex: 2, },
  ];

  const gridFilter: any = [
    { name: "id", operator: "startsWith", type: "string", value: "", },
    { name: "code", operator: "startsWith", type: "string", value: "", },
    { name: "name", operator: "startsWith", type: "string", value: "", },
  ];

  const [selectedRow, setSelectedRow] = useState(null as any);

  return <>
    <h1>Projects</h1>

    <Separator size={20} />

    <div className="row">
      <div className="col-3">

        <div className="card border-0 bg-light">
          <div className="card-body">

            <strong>Add Project</strong>

            <Separator size={20} />

            <form onSubmit={formProject.handleSubmit}>
              <div className="form-group">
                <label>Project Code</label>
                <input
                  type="text"
                  name="code"
                  className="form-control"
                  placeholder="Code"
                  autoComplete="off"
                  maxLength={2}
                  disabled={loadingCreateProject || loadingProjects}
                  value={formProject.values.code}
                  onChange={formProject.handleChange} />
              </div>

              <Separator size={20} />

              <div className="form-group">
                <label>Project Name</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Project Name"
                  autoComplete="off"
                  disabled={loadingCreateProject || loadingProjects}
                  value={formProject.values.name}
                  onChange={formProject.handleChange} />
              </div>

              <Separator size={20} />

              <div className="d-grid">
                <button
                  type="submit"
                  disabled={!formProject.isValid || loadingCreateProject || loadingProjects}
                  className="btn btn-primary">
                    <Loading loading={loadingCreateProject} parent="inline" color="text-white" />
                    {!loadingCreateProject && <i className="fas fa-check me-2"></i>}
                    Add Project
                  </button>
              </div>
            </form>

          </div>
        </div>

      </div>
      <div className="col">

        {!loadingProjects && projects.length === 0 && <>No projects found</>}

        <div className="row align-items-center">
          <div className="col">
            <small className="text-muted">{projects.length || 0} projects found</small>
          </div>
          <div className="col text-end">

            <button
              type="button"
              disabled={loadingProjects}
              onClick={() => listProjects()}
              className="btn btn-link btn-sm me-3">
                <Loading loading={loadingProjects} parent="inline" />
                {!loadingProjects && <i className="fas fa-sync me-2"></i>} 
                refresh
            </button>

            <button
              type="button"
              disabled={!selectedRow || !selectedRow.id || loadingDeleteProject}
              onClick={() => _deleteProject(selectedRow.id)}
              className="btn btn-outline-primary btn-sm">
                <Loading loading={loadingDeleteProject} parent="inline" />
                {!loadingDeleteProject && <i className="fas fa-trash me-2"></i>} 
                Remove Selected Project
            </button>
          </div>
        </div>

        <Separator size={10} />

        <Loading loading={loadingProjects} />

        {!loadingProjects && projects.length > 0 && <>

          <ReactDataGrid
            idProperty="id"
            columns={gridColumns}
            dataSource={projects}
            style={gridStyle}
            pagination={false}
            defaultFilterValue={gridFilter}
            enableSelection
            onSelectionChange={(selection: any) => {
              if (selection.data.length === 0) {
                setSelectedRow(null);
                return;
              }
              setSelectedRow(selection.data);
            }}
          />
        </>}


      </div>
    </div>
  </>
};

export default Projects;