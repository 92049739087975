import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { getMaterialDataById, getPartDataById } from '../../services/helpers.service';
import { checkIsPartNumberAvailable, deletePart, getPartRevisions, updatePart } from '../../services/parts.service';
import PartRelationshipTreeView from '../PartRelationshipTreeView';
import {
  AlertContext,
  Loading,
  Separator,
  convertFirebaseTimestampToString,
  Expander,
} from "@aptitude/aptitudehelpers/build"
import Status from '../Status/Status.component';
import _ from 'lodash';
import Documents from '../Documents/Documents.component';

const FormUpdateParts = (props: any) => {
  const {
    parts,
    selectedPart,
    onSetSelectedPart,
    onUpdatePart,
    onDeletePart,
    materials,
    templates,
  } = props;

  const { setAlert } = useContext(AlertContext);

  const [showModal, setShowModal] = useState(false);
  const [loadingUpdatePart, setLoadingUpdatePart] = useState(false);

  useEffect(() => {
    if (!showModal) {
      setSearchParent("");
      onSetSelectedPart(null);
      setIsPartNumberAvailable(null);
      return;
    }
  }, [showModal]);

  useEffect(() => {
    if (selectedPart) {
      _getPartRevisions(selectedPart.id);
      formUpdatePart.setValues(selectedPart);
      setShowModal(true);
      return;
    }

    formUpdatePart.setValues(formUpdatePartDefaultValues);
  }, [selectedPart]);

  const _openRevision = (revision: any): void => {
    onSetSelectedPart(revision);
  };

  const [partRevisions, setPartRevisions] = useState([] as any);
  const [loadingPartRevisions, setLoadingPartRevisions] = useState(false);
  const _getPartRevisions = async (partId: string): Promise<void> => {
    setLoadingPartRevisions(true);
    await getPartRevisions(partId).then((response: any) => {
      if (response.message === "OK") {
        setPartRevisions(response.revisions);
        setLoadingPartRevisions(false);
        return;
      }

      console.log("Revisions", response);
      setLoadingPartRevisions(false);
      setAlert({type: "danger", message: "Error getting part revisions"});
    }).catch((err: any) => {
      console.log("Revisions ERROR", err);
      setAlert({type: "danger", message: "Error getting part revisions"});
      setLoadingPartRevisions(false);
    });
  };

  const formUpdatePartDefaultValues: any = {
    id: "",
    type: "",
    partNumber: "",
    description: [],
    status: "",
    parentId: "",
    alternativeTo: "",
    partsOfAssembly: [],
    updateAction: "", // <empty> || increase_partNumber || increase_revision
  };

  const rule_checkPartNumber = (part: any): boolean => {
    console.log("RULE", part)
    return true;
  };

  const formUpdatePart: any = useFormik({
    initialValues: selectedPart || formUpdatePartDefaultValues,
    onSubmit: async (values: any) => {
      setLoadingUpdatePart(true);

      _checkIfPartNumberCanBeUsed();

      // Add custom validation logics here
      // eg: check part numbers across system, copy data depending on the alternativeTo, etc.
      if (!rule_checkPartNumber(values)) {
        setAlert({type: "danger", message: 'Data not met criterias for "checkPartNumber"'});
        setLoadingUpdatePart(false);
        return false;
      }

      await updatePart(values.id, values).then((response: any) => {
        if (response.message === "OK") {
          onUpdatePart(response.part);
          setShowModal(false);
          setSavePartAction(null);
          onSetSelectedPart(null);
          setLoadingUpdatePart(false);
          formUpdatePart.setFieldValue("updateAction", "");
          setAlert({type: "success", message: `Part ${values.partNumber || ""} updated`});
          return;
        }

        console.log("Error updating part", response);
        formUpdatePart.setFieldValue("updateAction", "");
        setLoadingUpdatePart(false);
        setAlert({type: "danger", message: `Error on update part ${values.partNumber}`});
      }).catch((err: any) => {
        console.log("Catch error updating part", err);
        formUpdatePart.setFieldValue("updateAction", "");
        setLoadingUpdatePart(false);
        setAlert({type: "danger", message: `Error on update part ${values.partNumber}`});
      });
    }
  });

  useEffect(() => {
    if (formUpdatePart.values.alternativeTo && formUpdatePart.values.alternativeTo !== "") {
      const currentPart: any = formUpdatePart.values;
      let alternativeToBase: any = parts.find((item: any) => item.id === formUpdatePart.values.alternativeTo);

      alternativeToBase = {
        ...currentPart,
        ...alternativeToBase,

        // These lines will:
        // Prevent to update the current ID
        // Keep the selected alternativeTo instead of the alternativeTo of the selected part
        id: currentPart.id,
        alternativeTo: currentPart.alternativeTo,
      };

      formUpdatePart.setValues(alternativeToBase);
    }
  }, [formUpdatePart.values.alternativeTo]);

  const [similarPart, setSimilarPart] = useState(null as any);

  useEffect(() => {
    const _checkParts = () => {
      setSimilarPart(null);
      let isSimilar: boolean = false;
      if (parts.length > 0) {
        parts.forEach((part: any) => {

          const partId: string = part.id;
          const partFormId: string = formUpdatePart.values.id;

          const objPart: any = {
            description: part.description,
            parents: part.parents,
            partsOfAssembly: part.partsOfAssembly,
            type: part.type,
          };

          const objFormPart: any = {
            description: formUpdatePart.values.description,
            parents: formUpdatePart.values.parents,
            partsOfAssembly: formUpdatePart.values.partsOfAssembly,
            type: formUpdatePart.values.type,
          };

          if (partId !== partFormId && _.isEqual(objPart, objFormPart)) {
            isSimilar = true;
            const similarPart: any = parts.find((item: any) => item.id === part.id);
            setSimilarPart(similarPart.partNumber || "");
            return;
          }
        });
      }
    };
    _checkParts();
  }, [formUpdatePart.values]);

  const [searchParent, setSearchParent] = useState("");
  const [filteredParents, setFilteredParents] = useState([] as any);

  useEffect(() => {
    if (searchParent !== "") {
      const filtered: any = parts.filter((item: any) => {
        const regex: any = new RegExp(searchParent, "gi");
        return item.partNumber.match(regex);
      });
      setFilteredParents(filtered);
    }
  }, [searchParent]);

  const _addFoundPartToRelationship = (targetList: string, partId: string): void => {
    let list: any = formUpdatePart.values[targetList];
    const partToAdd: any = parts.find((item: any) => item.id === partId);

    if (list && list.length > 0) {
      if (list.find((item: any) => item.id === partId)) {
        return;
      }

      list.push(partToAdd);
      return;
    }

    list = [partToAdd];
    formUpdatePart.setFieldValue(targetList, list);
  };

  const _removePartFromRelationship = (targetList: string, partId: string): void => {
    const filteredList: any = formUpdatePart.values[targetList].filter((item: any) => item.id !== partId);
    formUpdatePart.setFieldValue(targetList, filteredList);
  };

  const [loadingDeletePart, setLoadingDeletePart] = useState(false);
  const _deletePart = async (partId: string, action: string): Promise<void> => {
    if (action === "delete") {
      setLoadingDeletePart(true);
      await deletePart(partId).then((response: any) => {
        if (response.message === "OK") {
          onDeletePart(partId);
          setAlert({type: "success", message: `Part "${selectedPart.partNumber || partId}" removed`});
          setShowModal(false);
          setLoadingDeletePart(false);
          return;
        }
        setAlert({type: "danger", message: `Error deleting part "${selectedPart.partNumber || partId}"`});
        setLoadingDeletePart(false);
      }).catch((err: any) => {
        setAlert({type: "danger", message: `Error deleting part "${selectedPart.partNumber || partId}"`});
        setLoadingDeletePart(false);
      });
      return;
    }

    if (action === "unlink_parents") {
      // Unlink from parents
      // await unlinkPartFromParents(partId).then((response: any) => response).catch((err: any) => err);
      // setParts(parts.map((item: any) => item.id === partId ? {...item, parents: []} : item));
      // setAlert({type: "success", message: `Part ${partId} unlinked from parents`});
      return;
    }

    if (action === "unlink_children") {
      // Unlink from children
      // await unlinkPartFromChildren(partId).then((response: any) => response).catch((err: any) => err);
      // setParts(parts.map((item: any) => item.id === partId ? {...item, partsOfAssembly: []} : item));
      // setAlert({type: "success", message: `Part ${partId} unlinked from children`});
      return;
    }

    if (action === "unlink_relationship") {
      // Unlink from parents and children
      // await unlinkPartFromParents(partId).then((response: any) => response).catch((err: any) => err);
      // await unlinkPartFromChildren(partId).then((response: any) => response).catch((err: any) => err);
      // setParts(parts.map((item: any) => item.id === partId ? {...item, parents: [], partsOfAssembly: []} : item));
      // setAlert({type: "success", message: `Part ${partId} unlinked from parents and children`});
      return;
    }
  };

  const [isPartNumberAvailable, setIsPartNumberAvailable] = useState(null as any);
  const [loadingPartNumberValidation, setLoadingPartNumberValidation] = useState(false);
  const _checkIfPartNumberCanBeUsed = async (): Promise<void> => {
    const formData: any = formUpdatePart.values;
    if (formData.partNumber === "" || formData.partNumber.length !== 11) {
      return;
    }

    setLoadingPartNumberValidation(true);
    const payload: any = {
      id: formData.id,
      partNumber: formData.partNumber,
    };

    await checkIsPartNumberAvailable(payload).then((response: any) => {
      if (response.message === "OK") {
        setIsPartNumberAvailable(response.canIUseThisPartNumber);
        setLoadingPartNumberValidation(false);
        return;
      }
      setAlert({type: "danger", message: "Part Number validation failed"});
      setIsPartNumberAvailable(false);
      setLoadingPartNumberValidation(false);
    }).catch((err: any) => {
      console.log("ERR CHECK DUPLICATES >> ", err);
      setAlert({type: "danger", message: "Part Number validation failed"});
      setIsPartNumberAvailable(false);
      setLoadingPartNumberValidation(false);
    });
  };

  const [savePartAction, setSavePartAction] = useState(null as any);
  useEffect(() => {
    if (["increase_partNumber", "increase_revision"].includes(savePartAction)) {
      formUpdatePart.setFieldValue("updateAction", savePartAction);
      formUpdatePart.handleSubmit();
    }
  }, [savePartAction]);

  const [partsToCompare, setPartsToCompare] = useState([] as any);

  return <>
    <Modal show={showModal} size="xl">
      <Modal.Body className='p-0'>

        {selectedPart && <>
          <form onSubmit={formUpdatePart.handleSubmit}>

            <div className="bg-light p-3 pb-0">

              <div className="row">
                <div className="col-6">
                  <span className="text-muted">
                    <small>Edit Part</small> &rarr; <strong>{selectedPart.partNumber ? selectedPart.partNumber.toUpperCase() : "N/A"}</strong>
                  </span>
                </div>
                <div className="col-6 text-end">
                  <small className="text-muted">
                    ID: {selectedPart && selectedPart.id && <>{selectedPart.id}</>}<br />
                    Created At: {convertFirebaseTimestampToString(selectedPart.createdAt)}<br />
                    Last Update: {convertFirebaseTimestampToString(selectedPart.updatedAt)}
                  </small>
                </div>
              </div>

              <Separator size={20} />

              <div className="row">
                <div className="col">

                  {/* ALTERNATIVE TO */}

                  <div className="form-group">
                    <label>Alternative to:</label>
                    <select
                      name="alternativeTo"
                      className="form-control"
                      onChange={formUpdatePart.handleChange}
                      value={formUpdatePart.values.alternativeTo}>
                        <option value="">-</option>
                        {parts.map((item: any, index: number) => 
                          <option
                            disabled={item.id === selectedPart.id}
                            key={`partList-option-alternativeTo-${item.id}-${index}`}
                            value={item.id}>{item.partNumber}</option>
                        )}
                    </select>
                  </div>

                </div>
                <div className="col">

                  {/* STATUS */}

                  <div className="form-group">
                    <label>Status</label>
                    <select
                      name="status"
                      className="form-control"
                      onChange={formUpdatePart.handleChange}
                      value={formUpdatePart.values.status}>
                        <option value="">-</option>
                        <option value="current">current</option>
                        <option value="retired">retired</option>
                        <option value="developing">developing</option>
                        <option value="alternative">alternative</option>
                    </select>
                  </div>

                </div>
              </div>

              <Separator size={20} />

              {/* TABS */}

              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="info-tab" data-bs-toggle="tab" data-bs-target="#info-tab-pane" type="button" role="tab" aria-controls="info-tab-pane" aria-selected="true">
                    <i className="fas fa-info-circle me-2"></i>
                    Part Info
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="partNumber-tab" data-bs-toggle="tab" data-bs-target="#partNumber-tab-pane" type="button" role="tab" aria-controls="partNumber-tab-pane" aria-selected="true">
                    <i className="fas fa-list-ol me-2"></i>
                    Part Number
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="relationship-tab" data-bs-toggle="tab" data-bs-target="#relationship-tab-pane" type="button" role="tab" aria-controls="relationship-tab-pane" aria-selected="false">
                    <i className="fas fa-code-branch me-2"></i>
                    Relationship
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="revisions-tab" data-bs-toggle="tab" data-bs-target="#revisions-tab-pane" type="button" role="tab" aria-controls="revisions-tab-pane" aria-selected="false">
                    <i className="fas fa-columns me-2"></i>
                    Revisions Comparison
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="documents-tab" data-bs-toggle="tab" data-bs-target="#documents-tab-pane" type="button" role="tab" aria-controls="documents-tab-pane" aria-selected="false">
                    <i className="fas fa-copy me-2"></i>
                    Documents
                  </button>
                </li>
              </ul>

            </div>
            {/* /.bg-light */}

            <Separator size={20} />

            <div className="p-3">
              <div className="tab-content" id="myTabContentPart">

                {/* TAB INFO */}
                <div className="tab-pane show active" id="info-tab-pane" role="tabpanel" aria-labelledby="info-tab">
                  <div className="form-group">
                    <label>Part Type</label>
                    <select
                      name="type"
                      className="form-control"
                      onChange={formUpdatePart.handleChange}
                      value={formUpdatePart.values.type}>
                        <option value="">Select a type</option>
                        {materials.map((item: any, index: number) => 
                          <option key={`materialList-option-type-${item.id}-${index}`} value={item.id}>{item.code} - {item.name}</option>
                        )}
                    </select>
                  </div>

                  <Separator size={10} />

                  <div className="form-group">
                    <label>Description</label>
                    {formUpdatePart.values.description && formUpdatePart.values.description.length > 0 && <>
                      {formUpdatePart.values.description.map((item: any, index: number) => {
                        return (
                          <div className="row mb-1 align-items-center" key={`description-array-${index}`}>
                            <div className="col-1">
                              <small className='text-muted'>#{index + 1}</small>
                            </div>
                            <div className="col">
                                <input
                                  type="text"
                                  placeholder="Description"
                                  className="form-control"
                                  autoComplete='off'
                                  onChange={(e: any) => {
                                    const updated: any = formUpdatePart.values.description.map((i: any, iIndex: number) => iIndex === index ? e.target.value : i);
                                    formUpdatePart.setFieldValue("description", updated);
                                  }}
                                  name="description"
                                  value={item} />
                            </div>
                            <div className="col-1">

                              <button
                                type="button"
                                onClick={() => {
                                  const filtered: any = formUpdatePart.values.description.filter((i: any, iIndex: number) => iIndex !== index);
                                  formUpdatePart.setFieldValue("description", filtered);
                                }}
                                className="btn btn-outline-danger btn-sm">
                                  <i className="fas fa-times-circle"></i>
                              </button>

                            </div>
                          </div>
                        );
                      })}
                    </>}

                    <Separator size={10} />

                    <button
                      type="button"
                      onClick={() => {
                        formUpdatePart.setFieldValue("description", [...formUpdatePart.values.description, ""]);
                      }}
                      className="btn btn-link btn-sm">
                        <i className="fas fa-plus-circle me-2"></i> Add new description
                    </button>
                  </div>

                  {/* DYNAMIC FIELDS FROM TEMPLATES */}

                  {templates.map((template: any, index: number) => {
                    if ([formUpdatePart.values[template.field], "any_value"].includes(template.fieldValue)) {
                      return template.fields.map((field: any, index: number) => {
                        return (
                          <div key={`templates-dynamic-${template.fieldValue}-${template.id}-${index}`} className='form-group'>
                            <Separator size={20} />
                            <label htmlFor="">{field.label}</label>
                            <input
                              type="text"
                              name={field.name}
                              placeholder={field.placeholder}
                              autoComplete='off'
                              className="form-control"
                              value={formUpdatePart.values[field.name] || ""}
                              onChange={(e: any) => formUpdatePart.setFieldValue(field.name, e.target.value)} />
                          </div>
                        );
                      });
                    }
                  })}
                </div>

                {/* TAB PART NUMBER */}
                <div className="tab-pane" id="partNumber-tab-pane" role="tabpanel" aria-labelledby="partNumber-tab">

                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="">Part Number</label>
                        <input
                          type="text"
                          name="partNumber"
                          disabled={loadingUpdatePart || loadingDeletePart}
                          placeholder="eg: ADA-01-A001"
                          className="form-control"
                          autoComplete='off'
                          onChange={formUpdatePart.handleChange}
                          onKeyUp={() => _checkIfPartNumberCanBeUsed()}
                          value={formUpdatePart.values.partNumber || ""} />

                          <Separator size={5} />

                          <small>
                            <Loading loading={loadingPartNumberValidation} parent="inline" color="text-muted" />
                            {!loadingPartNumberValidation && isPartNumberAvailable && <span className="text-success"><i className="fa fa-check-circle me-2"></i> {formUpdatePart.values.partNumber} is valid {formUpdatePart.values.partNumber === selectedPart.partNumber && "(current)"}</span>}
                            {!loadingPartNumberValidation && isPartNumberAvailable === false && <span className="text-danger"><i className="fa fa-times-circle me-2"></i> {formUpdatePart.values.partNumber} is not valid</span>}
                          </small>
                      </div>
                    </div>
                  </div>
                </div>

                {/* TAB RELATIONSHIP */}
                <div className="tab-pane" id="relationship-tab-pane" role="tabpanel" aria-labelledby="relationship-tab">

                  <div className="row">
                    <div className="col-7 offset-md-5">

                      <div className="input-group">
                        <span className="input-group-text">Search by</span>
                        <select className="form-control">
                          <option value="">Part Number</option>
                          <option value="" disabled>Description</option>
                          <option value="" disabled>Type</option>
                        </select>
                        <input
                          type="text"
                          name="searchParent"
                          className="form-control uppercase"
                          value={searchParent}
                          autoComplete='off'
                          onChange={(e: any) => setSearchParent(e.target.value)}
                          placeholder="eg: AAS-02-A001" />
                      </div>

                    </div>
                  </div>

                  <Separator size={5} />

                  {searchParent !== "" && <>
                    <div className="search-results-block">
                      <div className="row align-items-center">
                        <div className="col">
                          <small className="text-muted">Search results ({filteredParents.length} parts found):</small>
                        </div>
                        <div className="col text-end">
                          <button
                            type="button"
                            onClick={() => setSearchParent("")}
                            className="btn btn-link inline btn-sm cursor-pointer">
                              <i className="fas fa-times-circle me-2"></i> clear search
                          </button>
                        </div>
                      </div>

                      <Separator size={5} />

                      <ul className="list-group">
                        {filteredParents.map((part: any, index: number) => {

                          // Can add to parent or children IF:
                          // - it's not itself
                          // - it's not in the list of parents already
                          // - it's not in the children list

                          const canAddToRelationship: boolean = 
                            (part.id === formUpdatePart.values.id) ||
                            (formUpdatePart.values.parents && formUpdatePart.values.parents.length > 0 && formUpdatePart.values.parents.find((i: any) => i.id === part.id)) || 
                            (formUpdatePart.values.partsOfAssembly && formUpdatePart.values.partsOfAssembly.length > 0 && formUpdatePart.values.partsOfAssembly.find((i: any) => i.id === part.id));

                          return (
                            <li key={`partList-parents-${part.id}-${index}`} className="list-group-item">

                              <div className="row align-items-center">
                                <div className="col-6">
                                  {part.partNumber}
                                </div>
                                <div className="col text-end">
                                  <button
                                    type="button"
                                    disabled={canAddToRelationship}
                                    onClick={() => _addFoundPartToRelationship("parents", part.id)}
                                    className="btn btn-secondary btn-sm cursor-pointer me-2">
                                      <i className="fas fa-plus-circle me-2"></i>
                                      Parents
                                  </button>

                                  <button
                                    type="button"
                                    disabled={canAddToRelationship}
                                    onClick={() => _addFoundPartToRelationship("partsOfAssembly", part.id)}
                                    className="btn btn-secondary btn-sm cursor-pointer">
                                      <i className="fas fa-plus-circle me-2"></i>
                                      Children
                                  </button>
                                </div>
                              </div>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </>}

                  <Separator size={20} />

                  <div className="row">
                    <div className="col-6">

                      {/* PARENTS */}

                      {formUpdatePart.values.parents && formUpdatePart.values.parents.length === 0 && <>No parents</>}

                      {formUpdatePart.values.parents && formUpdatePart.values.parents.length > 0 && <>
                        <strong>Parents</strong> <small className="text-muted">({formUpdatePart.values.parents.length} parts)</small>

                        <Separator size={5} />

                        <ul className='list-group'>
                          {formUpdatePart.values.parents.map((partParentChecked: any, indexParentChecked: number) => {
                            return <li key={`partList-parents-checked-${partParentChecked.id}-${indexParentChecked}`} className='list-group-item'>
                              <div className="float-end">
                                <i onClick={() => _removePartFromRelationship("parents", partParentChecked.id)} className="fas fa-times-circle text-danger cursor-pointer"></i>
                              </div>

                              {getPartDataById(parts, partParentChecked, "partNumber")}
                            </li>
                          })}
                        </ul>
                      </>}

                    </div>
                    <div className="col-6">

                      {/* CHILDREN */}

                      {formUpdatePart.values.partsOfAssembly && formUpdatePart.values.partsOfAssembly.length === 0 && <>No children</>}

                      {formUpdatePart.values.partsOfAssembly && formUpdatePart.values.partsOfAssembly.length > 0 && <>
                        <strong>Parts of this assembly (children)</strong> <small className="text-muted">({formUpdatePart.values.partsOfAssembly.length} parts)</small>

                        <Separator size={5} />

                        <ul className='list-group'>
                          {formUpdatePart.values.partsOfAssembly && formUpdatePart.values.partsOfAssembly.length > 0 && formUpdatePart.values.partsOfAssembly.map((partParentChecked: any, indexParentChecked: number) => {
                            return <li key={`partList-parents-checked-${partParentChecked.id}-${indexParentChecked}`} className='list-group-item'>
                              <div className="float-end">
                                <i onClick={() => _removePartFromRelationship("partsOfAssembly", partParentChecked.id)} className="fas fa-times-circle text-danger cursor-pointer"></i>
                              </div>

                              {getPartDataById(parts, partParentChecked, "partNumber")}
                            </li>
                          })}
                        </ul>
                      </>}

                    </div>
                  </div>

                  <Separator size={20} />

                  <PartRelationshipTreeView
                    parts={parts}
                    formUpdatePart={formUpdatePart}
                    selectedPart={selectedPart} />

                </div>

                {/* TAB REVISIONS */}
                <div className="tab-pane" id="revisions-tab-pane" role="tabpanel" aria-labelledby="revisions-tab">

                  <Loading loading={loadingPartRevisions} />

                  {!loadingPartRevisions && parts && parts.filter((p: any) => p.type === selectedPart.type).length > 0 && <>
                    Select all the <strong>"{getMaterialDataById(materials, selectedPart.type, "code")} - {getMaterialDataById(materials, selectedPart.type, "name")}"</strong> you want to compare

                    <Separator size={10} />

                    <div className="row">
                      {(parts.filter((p: any) => p.type === selectedPart.type)).map((part: any, index: number) => {
                        return <div key={`partList-revisions-${part.id}-${index}`} className="col-3">
                          <label>
                            <input
                              className="form-check-input me-2"
                              type="checkbox"
                              value={part.id}
                              onChange={(e: any) => {
                                if (e.target.checked) {
                                  setPartsToCompare([...partsToCompare, part]);
                                } else {
                                  setPartsToCompare(partsToCompare.filter((item: any) => item.id !== part.id));
                                }
                              }} />
                              {part.partNumber}
                          </label>
                        </div>
                      })}
                    </div>

                    {partsToCompare.length > 0 && <>
                      <Separator size={10} />

                      <div className="table-responsive">
                        <table className='table table-striped table-bordered'>
                          <thead>
                            <tr>
                              <th className='no-wrap'>Part Number</th>
                              <th className='no-wrap'>Alternative To</th>
                              <th>Description</th>
                              <th className='no-wrap'>Status</th>
                              <th className='no-wrap'>Parents</th>
                              <th className='no-wrap'>Children</th>
                            </tr>
                          </thead>
                          <tbody>
                            {partsToCompare.length > 0 && partsToCompare.map((ptc: any, index: number) => {
                              return (
                                <tr key={`partList-revisions-${ptc.id}-${index}`}>
                                  <td>{ptc.partNumber}</td>
                                  <td>
                                    {getPartDataById(parts, ptc.alternativeTo, "partNumber")}
                                  </td>
                                  <td>
                                    {ptc.description && ptc.description.length > 0 && <>
                                      <Expander contentId={`expander-description-${index}`} labelExpand="View Description" labelCollapse="Hide Description">
                                        <ul className="list-group">
                                          {ptc.description.map((desc: any, index: number) => {
                                            return <li key={`partList-revisions-${ptc.id}-${index}`} className='list-group-item'>{desc}</li>
                                          })}
                                        </ul>
                                      </Expander>
                                    </>}
                                  </td>
                                  <td>
                                    <Status status={ptc.status} />
                                  </td>
                                  <td>
                                    {ptc.parents && ptc.parents.length > 0 && <>
                                      <ul className='list-group'>
                                        {ptc.parents.map((parentItem: any, index: number) => {
                                          return <li key={`partList-revisions-${ptc.id}-${index}`} className='list-group-item'>
                                            {getPartDataById(parts, parentItem, "partNumber")}
                                          </li>
                                        })}
                                      </ul>
                                    </>}
                                  </td>
                                  <td>
                                    {ptc.partsOfAssembly && ptc.partsOfAssembly.length > 0 && <>
                                      <ul className='list-group'>
                                        {ptc.partsOfAssembly.map((childItem: any, index: number) => {
                                          return <li key={`partList-revisions-${ptc.id}-${index}`} className='list-group-item'>
                                            {getPartDataById(parts, childItem, "partNumber")}
                                          </li>
                                        })}
                                      </ul>
                                    </>}
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </>}
                  </>}

                  {!loadingPartRevisions && partRevisions.length > 0 && <>
                    <div className="table-responsive d-none">
                      <table className="table table-sm table-striped table-bordered">
                        <thead>
                          <tr>
                            <td className="text-muted"></td>
                            <td>
                              <small className="text-muted">Current Part</small>
                            </td>
                            {partRevisions.map((revisionItem: any, index: number) => {
                              return <th key={`partList-revisions-${revisionItem.id}-${index}`} className="no-wrap-header">
                                <small>
                                  <span className="text-muted me-1">rev</span>
                                  <button
                                    type="button"
                                    className='btn btn-link inline'
                                    onClick={() => _openRevision(revisionItem)}>
                                      {revisionItem.partNumber}
                                  </button>
                                </small>
                              </th>
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-muted no-wrap-header"><small>Part Number</small></td>
                            <td className="no-wrap">{selectedPart.partNumber}</td>
                            {partRevisions.map((revisionItem: any, index: number) => {
                              return <td key={`partList-revisions-${revisionItem.id}-${index}`}>{revisionItem.partNumber}</td>
                            })}
                          </tr>
                          <tr>
                            <td className="text-muted no-wrap-header"><small>Alternative To</small></td>
                            <td className="no-wrap">
                              {getPartDataById(parts, selectedPart.alternativeTo, "partNumber")}
                            </td>
                            {partRevisions.map((revisionItem: any, index: number) => {
                              return <td key={`partList-revisions-${revisionItem.id}-${index}`}>
                                {getPartDataById(parts, revisionItem.alternativeTo, "partNumber")}
                              </td>
                            })}
                          </tr>
                          <tr>
                            <td className="text-muted no-wrap-header"><small>Parents</small></td>
                            <td>
                              {selectedPart.parents && selectedPart.parents.length > 0 && <>
                                <ul className='list-group'>
                                  {selectedPart.parents.map((parentItem: any, index: number) => {
                                    return <li key={`partList-revisions-current-${parentItem.id}-${index}`} className='list-group-item no-wrap'>
                                      {parentItem.partNumber}
                                    </li>
                                  })}
                                </ul>
                              </>}
                            </td>

                            {partRevisions.map((revisionItem: any, index: number) => {
                              return <td key={`partList-revisions-${revisionItem.id}-${index}`}>
                                {revisionItem.parents && revisionItem.parents.length > 0 && <>
                                  <ul className='list-group'>
                                    {revisionItem.parents.map((parentItem: any, index: number) => {
                                      return <li key={`partList-revisions-${revisionItem.id}-${index}`} className='list-group-item no-wrap'>
                                        {parentItem.partNumber}
                                      </li>
                                    })}
                                  </ul>
                                </>}
                              </td>
                            })}
                          </tr>
                          <tr>
                            <td className="text-muted no-wrap-header"><small>Parts of Assembly</small></td>
                            <td>
                              {selectedPart.partsOfAssembly && selectedPart.partsOfAssembly.length > 0 && <>
                                <ul className='list-group'>
                                  {selectedPart.partsOfAssembly.map((childItem: any, index: number) => {
                                    return <li key={`partList-revisions-current-${childItem.id}-${index}`} className='list-group-item no-wrap'>
                                      {childItem.partNumber}
                                    </li>
                                  })}
                                </ul>
                              </>}
                            </td>

                            {partRevisions.map((revisionItem: any, index: number) => {
                              return <td key={`partList-revisions-${revisionItem.id}-${index}`}>
                                {revisionItem.partsOfAssembly && revisionItem.partsOfAssembly.length > 0 && <>
                                  <ul className='list-group'>
                                    {revisionItem.partsOfAssembly.map((childItem: any, index: number) => {
                                      return <li key={`partList-revisions-${childItem.id}-${index}`} className='list-group-item no-wrap'>
                                        {childItem.partNumber}
                                      </li>
                                    })}
                                  </ul>
                                </>}
                              </td>
                            })}
                          </tr>
                          <tr>
                            <td className="text-muted no-wrap-header"><small>Type</small></td>
                            <td className="no-wrap">
                              {getMaterialDataById(materials, selectedPart.type, "code")} - {getMaterialDataById(materials, selectedPart.type, "name")}
                            </td>
                            {partRevisions.map((revisionItem: any, index: number) => {
                              return <td key={`partList-revisions-${revisionItem.id}-${index}`}>{getMaterialDataById(materials, revisionItem.type, "code")} - {getMaterialDataById(materials, revisionItem.type, "name")}</td>
                            })}
                          </tr>
                          <tr>
                            <td className="text-muted no-wrap-header"><small>Description</small></td>
                            <td>
                              <span className='line-break break-content' dangerouslySetInnerHTML={{ __html: selectedPart.description }}></span>
                            </td>
                            {partRevisions.map((revisionItem: any, index: number) => {
                              return <td key={`partList-revisions-${revisionItem.id}-${index}`}>
                                <span className='line-break break-content' dangerouslySetInnerHTML={{ __html: revisionItem.description }}></span>
                              </td>
                            })}
                          </tr>
                          <tr>
                            <td className="text-muted no-wrap-header"><small>Status</small></td>
                            <td>
                              <Status status={selectedPart.status} />
                            </td>
                            {partRevisions.map((revisionItem: any, index: number) => {
                              return <td key={`partList-revisions-${revisionItem.id}-${index}`}><Status status={revisionItem.status} /></td>
                            })}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>}

                </div>

                {/* TAB DOCUMENTS */}
                <div className="tab-pane" id="documents-tab-pane" role="tabpanel" aria-labelledby="documents-tab">
                  <Documents part={selectedPart} />
                </div>

              </div>

              <Separator size={40} />

              {similarPart && <>
                <div className="alert alert-danger">
                  This part is similar to the part <strong>{similarPart}</strong>.<br />
                  <strong>Are you sure you want to proceed?</strong>
                </div>
              </>}

              <div>
                <button
                  className="btn btn-primary dropdown-toggle cursor-pointer btn-sm me-2"
                  type="button"
                  disabled={loadingUpdatePart}
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                    <Loading loading={loadingUpdatePart} parent="inline" color="text-white" />
                    {!loadingUpdatePart && <i className="fas fa-check-circle me-2"></i>}
                    <span className="me-2">Update Part</span>
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <button
                      disabled={loadingUpdatePart}
                      onClick={() => setSavePartAction("increase_partNumber")}
                      type="button"
                      className="dropdown-item">
                        <i className="fas fa-caret-right me-2"></i> Increase Part Number
                    </button>
                    <div className="m-3 mt-0 mb-0">
                      <small className="text-muted">
                        ADA-01-<strong className="text-primary">A0</strong>01 &rarr; ADA-01-<strong className="text-primary">B0</strong>01
                      </small>
                    </div>
                  </li>
                  <li><hr className="dropdown-divider" /></li>
                  <li>
                    <button
                      disabled={loadingUpdatePart}
                      onClick={() => setSavePartAction("increase_revision")}
                      type="button"
                      className="dropdown-item">
                        <i className="fas fa-caret-right me-2"></i> Increase Revision
                    </button>
                    <div className="m-3 mt-0 mb-0">
                      <small className="text-muted">
                        ADA-01-A0<strong className="text-primary">01</strong> &rarr; ADA-01-A0<strong className="text-primary">02</strong>
                      </small>
                    </div>
                  </li>
                  <li><hr className="dropdown-divider" /></li>
                  <li>
                    <button
                      type="submit"
                      disabled={loadingUpdatePart}
                      className="dropdown-item">
                        <i className="fas fa-caret-right me-2"></i> Don't change Part Number nor Revision
                    </button>
                  </li>
                </ul>

                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="btn btn-link cursor-pointer btn-sm me-2 text-muted">
                    <i className="fas fa-times-circle me-2"></i>
                    Cancel
                </button>

                <div className="float-end">
                  <div className="dropdown">
                    <button
                      className="btn btn-outline-danger dropdown-toggle cursor-pointer btn-sm"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                        <i className="fas fa-trash me-2"></i> 
                        <span className="me-2">Remove Part</span>
                    </button>
                    <ul className="dropdown-menu">
                      <li><button disabled onClick={() => _deletePart(selectedPart.id, "unlink_parents")} type="button" className="dropdown-item disabled"><i className="fas fa-unlink me-2"></i> Unlink from parents</button></li>
                      <li><button disabled onClick={() => _deletePart(selectedPart.id, "unlink_children")} type="button" className="dropdown-item disabled"><i className="fas fa-unlink me-2"></i> Unlink from children</button></li>
                      <li><button disabled onClick={() => _deletePart(selectedPart.id, "unlink_relationship")} type="button" className="dropdown-item disabled"><i className="fas fa-unlink me-2"></i> Unlink from parents and children</button></li>
                      <li><hr className="dropdown-divider" /></li>
                      <li>
                        <button
                          type='button'
                          disabled={loadingDeletePart}
                          onClick={() => _deletePart(selectedPart.id, "delete")}
                          className="dropdown-item">
                            <Loading loading={loadingDeletePart} parent="inline" color="text-danger" />
                            {!loadingDeletePart && <i className="fas fa-exclamation-triangle me-2"></i>} 
                            Delete Part
                        </button>
                        <div className="m-3 mt-0 mb-0">
                          <small className="text-muted">
                            Delete this part and all its references.<br />
                            This action cannot be undone
                          </small>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>


              </div>

            </div>{/* /.p-3 */}
          </form>
        </>}

      </Modal.Body>
    </Modal>
  </>
};

export default FormUpdateParts;