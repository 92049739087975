import { useContext, useEffect, useState } from "react";
import {Separator} from "@aptitude/aptitudehelpers/build";
import { DataContext } from "../context/Data.context";

const PartRelationshipTreeView = (props: any) => {
  const {
    // parts,
    formUpdatePart,
    selectedPart,
  } = props;

  const { data, setData } = useContext(DataContext);

  const [parts, setParts] = useState([] as any);

  useEffect(() => {
    setParts(data.parts || []);
  }, [data]);

  const [toggleVisualization, setToggleVisualization] = useState(false);

  return <>
    <div className="text-muted">
      <small>
      <div className="row align-items-center">
        <div className="col">
          <i className="fas fa-caret-right me-1"></i> Relationship Visualization for <strong>{formUpdatePart.values.partNumber}</strong>
        </div>
        <div className="col-1 text-end">
          <button
            type="button"
            className="btn btn-link btn-sm"
            onClick={() => setToggleVisualization(!toggleVisualization)}>
              {toggleVisualization ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i> }
          </button>
        </div>
      </div>
      </small>
    </div>

    {toggleVisualization && <>
      <Separator size={20} />

      <div className="row">
        <div className="col-1">
          <small className="text-muted">Parents</small>
        </div>

        {formUpdatePart.values.parents && formUpdatePart.values.parents.length > 0 && formUpdatePart.values.parents.map((item: any, index: number) => (
          <div key={`treeview-parent-${item}-${index}`} className="col text-center">
            <div className="rounded-2 bg-light pt-1 pb-1" style={{ border: "solid 1px #dee2e6" }}>{item.partNumber}</div>
            <i className="fas fa-arrow-up"></i>
          </div>
        ))}
      </div>

      <div className="row align-items-center">
        <div className="col-1">
          <small className="text-muted">This</small>
        </div>
        <div className="col-11 text-center">
          <div className="rounded-2 bg-light pt-2 pb-2" style={{ border: "solid 1px #dee2e6" }}>
            {selectedPart.partNumber || ""}
          </div>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-1">
            <small className="text-muted">Children</small>
        </div>
        <div className="col">

          <div className="text-center">
            <i className="fas fa-arrow-down"></i>
          </div>

          <div className="row justify-content-center">
            <div className="col-4">
              <ul className="list-group rounded-2">
                {formUpdatePart.values.partsOfAssembly && formUpdatePart.values.partsOfAssembly.length > 0 && formUpdatePart.values.partsOfAssembly.map((item: any, index: number) => (
                  <li key={`treeview-child-${item}-${index}`} className="list-group-item text-center bg-light pt-1 pb-1">
                    {item.partNumber}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>}
  </>
};

export default PartRelationshipTreeView;