import axios from 'axios';

export const getTemplates = async () => {
  return await axios.get("templates").then((response: any) => response.data).catch((err: any) => err);
}

export const createTemplate = async (payload: any) => {
  return await axios.post("templates", payload).then((response: any) => response.data).catch((err: any) => err);
}

export const deleteTemplate = async (templateId: string) => {
  return await axios.delete(`templates/${templateId}`).then((response: any) => response.data).catch((err: any) => err);
}
