import { useContext, useEffect, useState } from "react";
import DisplayCSV from "../../components/DisplayCSV.component";
import { getMaterials } from "../../services/materials.service";
import { getParts } from "../../services/parts.service";
import {
  AlertContext,
  ConfirmContext,
  Loading,
  Separator,
  ColumnsMatcher,
  Upload,
  parseCSV,
} from "@aptitude/aptitudehelpers";

const ImportCSV = () => {
  const { setAlert } = useContext(AlertContext);
  const { setConfirm } = useContext(ConfirmContext);

  const [loadingMaterials, setLoadingMaterials] = useState(false);
  const [materials, setMaterials] = useState([] as any);

  const _listMaterials = async () => {
    await getMaterials().then((response: any) => {
      setLoadingMaterials(true);
      if (response.message === "OK") {
        setMaterials(response.materials);
        setLoadingMaterials(false);
        return;
      }

      setAlert({ type: "danger", message: "Error" });
      setLoadingMaterials(false);
    }).catch((err: any) => {
      setAlert({ type: "danger", message: "Error" });
      setLoadingMaterials(false);
    });
  };

  const [loadingParts, setLoadingParts] = useState(false);
  const [parts, setParts] = useState([] as any);

  const _listParts = async () => {
    setLoadingParts(true);
    await getParts().then((response: any) => {
      if (response.message === "OK") {
        setParts(response.parts);
        setLoadingParts(false);
        return;
      }

      console.log("Error _listParts", response);
      setAlert({ type: "danger", message: "Error" });
      setLoadingParts(false);
    }).catch((err: any) => {
      console.log("Catch error _listParts", err);
      setAlert({ type: "danger", message: "Error" });
      setLoadingParts(false);
    });
  };

  useEffect(() => {
    _listParts();
    _listMaterials();
  }, []);

  const MaterialsList = ({currentValue}: any) => {
    return (
      <select
        className="form-select">
          <option value="">Select a material</option>
          {loadingMaterials && <option value="">Loading...</option>}
          {materials && materials.length > 0 && materials.map((material: any, index: number) => {
            return (
              <option
                key={`material-${currentValue}-${index}`}
                selected={currentValue === material.id || currentValue === material.name}
                value={material.id}>
                  {material.name}
              </option>
            )
          })}
      </select>
    )
  };

  const StatusList = ({currentValue}: any) => {
    const statuses: string[] = [
      "Current",
      "Alternative",
      "Adapte",
      "Retired",
      "Current",
      "Alternative",
      "Developing",
    ];
    return (
      <select
        className="form-select">
          <option value="">Select a status</option>
          {statuses.map((status: string, index: number) => {
            return <option
              selected={currentValue === status}
              key={`status-${currentValue}-${index}`}>
              {status}
            </option>
          })}
      </select>
    );
  };

  const [csvData, setCSVData] = useState<any>(null);

  useEffect(() => {
    csvData && setCSVData(csvData);
  }, [csvData]);

  return <>
    <Loading loading={loadingParts || loadingMaterials} />

    {!loadingParts && !loadingMaterials && parts.length > 0 && materials.length > 0 && <>

      {/* STEP 1 - CHOOSE CSV FILE */}

      <h3>
        <div className="badge bg-primary rounded-circle float-start me-3">1</div> Step 1: Choose a CSV file
      </h3>

      <Separator size={20} />

      <small className="text-muted">
        Rows highlighted in red contains "partNumber" that already exists in the database and will be ignored during the import process.
      </small>

      <Separator size={20} />

      <div className="row">
        <div className="col-4">
          <label>Choose a CSV file to import</label>

          <Separator size={10} />

          <Upload
            accept="text/csv"
            folder=""
            showProgress={true}
            loading={false}
            onUpload={(e: any) => {
              const csvData: any = e.content;
              if (csvData && csvData.length > 0) {
                const { headers } = parseCSV(csvData);
                setCSVData({ headers, csvData });
              }
            }} />

        </div>
      </div>

      {csvData && csvData.csvData && csvData.csvData.length > 0 && <>
        <Separator size={10} />

        <DisplayCSV
          data={csvData.csvData}
          columnId="AMS Internal PN"
          hightlightRows={{ column: "AMS Internal PN", isOneOf: parts.map((part: any) => part.partNumber || "") }}
          replacements={[
            {
              column: "Category",
              component: (value: any) => {
                return <MaterialsList currentValue={value} />;
              }
            },
            {
              column: "Status",
              component: (value: any) => {
                return <StatusList currentValue={value} />;
              }
            },
          ]}
        />
      </>}

      <Separator size={40} />


      {/* STEP 2 - MATCH COLUMNS */}

      <h3>
        <div className="badge bg-primary rounded-circle float-start me-3">2</div> Step 2: Match columns from CSV to Inventory
      </h3>

      {csvData && csvData.headers && csvData.headers.length > 0 && <>
        <Separator size={20} />

        <div className="row justify-content-center">
          <div className="col-7">

            <div className="card">
              <div className="card-body">

                <ColumnsMatcher
                  columnsFrom={csvData.headers}
                  columnsTo={[
                    "partNumber",
                    "type",
                    "status",
                    "description",
                    "manufacturer",
                    "manufacturerPN",
                    "supplier",
                    "supplierPN",
                    "price",
                    "currency",
                    "stock",
                    "stockLocation",
                    "minimumOrderQuantity",
                    "leadTime",
                    "notes",
                  ]}
                  fromDescription="Columns from CSV"
                  toDescription="Columns in the database"
                  onMatchColumns={(columns: any) => {
                    console.log(columns);
                  }}
                />

              </div>
            </div>

          </div>
        </div>
      </>}

      <Separator size={40} />


      {/* STEP 3 - CONFIRM IMPORT */}

      <h3>
        <div className="badge bg-primary rounded-circle float-start me-3">3</div> Step 3: Confirm import
      </h3>

      <Separator size={20} />

      Rows to import: 90

      <Separator size={20} />

      <small className="text-muted">Data object preview:</small>

      <Separator size={10} />

      {csvData && csvData.csvData && csvData.csvData.length > 0 && <>
        <div className="card card-body bg-light" style={{ maxHeight: "200px", overflow: "auto" }}>
          <pre>
            {JSON.stringify(csvData.csvData, null, 2)}
          </pre>
        </div>
      </>}

      <Separator size={20} />

      <button
        className="btn btn-primary"
        onClick={() => {
          setConfirm({
            type: "primary",
            title: "Import Data",
            message: "Are you sure you want to import this data into database?",
            buttonLabel: "I want to import data",
            onConfirm: () => console.log("Import CSV"),
          })
        }}>
          <i className="fas fa-check me-2"></i>
          Import data to Inventory Database
      </button>

    </>}{/* !loading parts || materials */}
  </>
};

export default ImportCSV;