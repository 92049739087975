const Status = (props: any) => {
    const {
        status,
        text,
    } = props;
    let bgColor: string = "";

    if (!status || typeof status !== 'string') {
        return <></>;
    }

    switch (status.toLowerCase()) {
        case 'current': bgColor = 'success'; break;
        case 'retired': bgColor = 'danger'; break;
        case 'developing': bgColor = 'warning'; break;
        case 'alternative': bgColor = 'primary'; break;
        default: bgColor = 'secondary'; break;
    }

    return <small>
        <span className={`badge bg-${bgColor}`}>
            {status.toUpperCase()} {text && <span dangerouslySetInnerHTML={{ __html: text }}></span>}
        </span>
    </small>
};

export default Status;