import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import axios from 'axios';
import Navbar from './components/Navbar/Navbar.component';
import {
  Alert,
  AlertContext,
  Confirm,
  ConfirmContext,
  Separator,
} from "@aptitude/aptitudehelpers/build";

import './App.scss';

axios.defaults.baseURL = process.env.REACT_APP_AXIOS_URL || "";

function App() {
  const { alert } = useContext(AlertContext);
  const { confirm } = useContext(ConfirmContext);
  const appVersion = process.env.REACT_APP_VERSION || "0.0.0";

  return <>
    <Alert alert={alert} />
    <Confirm confirm={confirm} />

    <Navbar />

    <div className="container">
      <Outlet />
    </div>

    <Separator size={50} />

    <footer className="text-center text-muted">
      <small>Inventory {appVersion}</small>
    </footer>
    <Separator size={100} />
  </>
}

export default App;
