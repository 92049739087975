import axios from 'axios';

export const getMaterials = async () => {
  return await axios.get("materials").then((response: any) => response.data).catch((err: any) => err);
}

export const createMaterial = async (payload: any) => {
  return await axios.post("materials", payload).then((response: any) => response.data).catch((err: any) => err);
}

export const deleteMaterial = async (materialId: string) => {
  return await axios.delete(`materials/${materialId}`).then((response: any) => response.data).catch((err: any) => err);
}
