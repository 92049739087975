import { useEffect, useState } from "react";
import { getParts } from "../services/parts.service";
import {
  Loading,
  Separator,
} from "@aptitude/aptitudehelpers/build";
import Status from "./Status/Status.component";

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';

// Recursive component to display parts as a tree
// item1
//  - item2
//    - item3
//      - itemN
const TreeView = (props: any) => {
  useEffect(() => {
    _getParts();
  }, []);

  const [parts, setParts] = useState([]);
  const [loadingParts, setLoadingParts] = useState(false);
  const _getParts = async () => {
    setLoadingParts(true);
    await getParts().then((response: any) => {
      setParts(response.parts);
      setLoadingParts(false);
    }).catch((error: any) => {
      console.error(error);
      setLoadingParts(false);
    });
  };

  const [selectedItems, setSelectedItems] = useState<any>(null);

  // Need to use DOM manipulation
  // Because of the way the tree is built
  // Using state to manage the selected items was causing problems
  const setFocus = (event: any, item: any) => {
    event.stopPropagation();
    event.preventDefault();

    setSelectedItems(null);

    console.log("item", item);

    setSelectedItems([item]);

    // for each item.parents and item.partsOfAssembly, add it to the selectedItems
    if (item.parents && item.parents.length > 0) {
      item.parents.map((parent: any) => {
        setSelectedItems((prev: any) => {
          return [...prev, parent];
        });
      });
    }

    if (item.partsOfAssembly && item.partsOfAssembly.length > 0) {
      item.partsOfAssembly.map((child: any) => {
        setSelectedItems((prev: any) => {
          return [...prev, child];
        });
      });
    }

    const selectedClass: string = "treeview-selected";

    // Remove all elements in DOM with class treeview-selected
    const elementsSelected = document.querySelectorAll(".treeview-selected");
    elementsSelected.forEach((element: any) => {
      element.classList.remove(selectedClass);
    });

    // If element is already selected, remove class and return
    if (event.target.classList.contains(selectedClass)) {
      event.target.classList.remove(selectedClass);
      return;
    }

    // Find all elements in DOM with class part-<id> and add class selected
    const elements = document.querySelectorAll(`.part-${item.id}`);
    elements.forEach((element: any) => {
      if (element.classList.contains(selectedClass)) {
        element.classList.remove(selectedClass);
      } else {
        element.classList.add(selectedClass);
      }
    });
  };

  // isHidden should hide the selected part (by its partNumber) from the TOP level when this partNumber is also found in "parents" or "partsOfAssembly" of any another part
  // For example, if partNumber "ADA-01-A001" is found in "parents" or "partsOfAssembly" of any part, it should be hidden from the TOP level
  const isHidden = (partNumber: any): boolean => {
    let hidden: boolean = false;

    if (parts && parts.length > 0) {
      parts.map((item: any) => {
        if (item.parents && item.parents.length > 0) {
          item.parents.map((parent: any) => {
            if (parent.partNumber === partNumber) {
              hidden = true;
            }
          });
        }

        if (item.partsOfAssembly && item.partsOfAssembly.length > 0) {
          item.partsOfAssembly.map((child: any) => {
            if (child.partNumber === partNumber) {
              hidden = true;
            }
          });
        }
      });
    }

    return hidden;
  };

  const gridStyle: any = { minHeight: "80vh" };

  const gridColumns: any = [
    { name: "id", header: "id", minWidth: 190, defaultFlex: 2, },
    {
      name: "type", header: "Type", minWidth: 170, defaultFlex: 2,
      render: (value: any) => {
        if (!value.data.type || value.data.type === "") {
          return <span className="text-body-tertiary">N/A</span>
        }
        // return `${getMaterialDataById(materials, value.data.type, "code")} - ${getMaterialDataById(materials, value.data.type, "name")}`;
        return value.data.type;
      }
    },
    { name: "partNumber", header: "Part Number", minWidth: 240, defaultFlex: 2, },
    {
      name: "description", header: "Description", minWidth: 270, defaultFlex: 2,
      render : (value: any) => {
        if (!value.data.description || value.data.description.length === 0) {
          return <span className="text-body-tertiary">N/A</span>
        }
        return value.data.description.join(", ");
      }
    },
    {
      name: "status", header: "Status", minWidth: 200, defaultFlex: 2,
      render: (value: any) => {
        if (value.data.status === "alternative") {
          return <Status status={value.data.status} text={`&rarr; ${value.data.alternativeTo}`} />
        }
        return <Status status={value.data.status} />
      },
    },
    {
      name: "parents", header: "Child Of", minWidth: 170, defaultFlex: 2,
      render: (value: any) => {
        if (!value.data.parents || value.data.parents.length === 0) {
          return <span className="text-body-tertiary">N/A</span>
        }
        const parents: any = [];
        for (let i = 0; i < value.data.parents.length; i++) {
          parents.push(value.data.parents[i].partNumber);
        }
        return parents.join(", ");
      }
    },
    {
      name: "alternativeTo", header: "Alternative To", minWidth: 170, defaultFlex: 2,
      render: (value: any) => {
        if (!value.data.alternativeTo || value.data.alternativeTo === "") {
          return <span className="text-body-tertiary">N/A</span>
        }
        // return getPartDataById(parts, value.data.alternativeTo, "partNumber");
        return value.data.alternativeTo;
      }
    },
    {
      name: "partsOfAssembly", header: "Parts in this assembly", minWidth: 470, defaultFlex: 2,
      render: (value: any) => {
        if (!value.data.partsOfAssembly || value.data.partsOfAssembly.length === 0) {
          return <span className="text-body-tertiary">N/A</span>
        }
        const partsOfAssemblyList: any = [];
        for (let i = 0; i < value.data.partsOfAssembly.length; i++) {
          partsOfAssemblyList.push(value.data.partsOfAssembly[i].partNumber);
        }
        return partsOfAssemblyList.join(", ");
      }
    },
    {
      name: "createdAt", header: "Created At", minWidth: 170, defaultFlex: 2,
      render: (value: any) => {
        // return convertFirebaseTimestampToString(value.data.createdAt);
        // return value.data.createdAt;
        return "";
      }
    },
    {
      name: "updatedAt", header: "Updated At", minWidth: 170, defaultFlex: 2,
      render: (value: any) => {
        // return convertFirebaseTimestampToString(value.data.updatedAt);
        return "";
      }
    },
  ];

  const gridFilter: any = [
    { name: "id", operator: "startsWith", type: "string", value: "", },
    { name: "type", operator: "startsWith", type: "string", value: "", },
    { name: "partNumber", operator: "startsWith", type: "string", value: "", },
    { name: "description", operator: "startsWith", type: "string", value: "", },
    { name: "status", operator: "startsWith", type: "string", value: "", },
    { name: "parentId", operator: "startsWith", type: "string", value: "", },
  ];

  return <>
    <Loading loading={loadingParts} />

    {!loadingParts && parts.length > 0 && <>
      <div className="row no-wrap-columns font-size-12">
        {parts.length > 0 && parts.map((item: any, indexTop: number) => {

          if (isHidden(item.partNumber)) {
            return null;
          }

          return (
            <div key={`treeview-top-${indexTop}-${item.id}`} className="col">
              <div className="card card-body no-wrap card-sm cursor-pointer">

                {item.parents && item.parents.length > 0 && <>
                  {item.parents.map((subItem: any, subIndex: number) => {
                    return (
                      <div
                        onClick={(e: any) => setFocus(e, subItem)}
                        key={`treeview-parent-${subItem.id}-${subIndex}`}
                        className={`part-${subItem.id}`}>
                          {subItem.partNumber}
                      </div>
                    );
                  })}
                </>}

                <div
                  onClick={(e: any) => setFocus(e, item)}
                  className={`card card-body no-wrap card-sm part-${item.id}`}>
                    {item.partNumber}

                    {item.partsOfAssembly && item.partsOfAssembly.length > 0 && <>
                      <Separator size={10} />

                      {item.partsOfAssembly.map((subItem: any, indexBottom: number) => {
                        return (
                          <div
                            onClick={(e: any) => setFocus(e, subItem)}
                            key={`treeview-bottom-${indexBottom}-${subItem.id}`}
                            className={`card card-body no-wrap mb-1 card-sm part-${subItem.id}`}>
                              {subItem.partNumber}

                              {subItem.partsOfAssembly && subItem.partsOfAssembly.length > 0 && <>
                                <TreeView parts={parts.filter((part: any) => part.id === subItem.id)} />
                              </>}
                          </div>
                        )
                      })}

                    </>}
                </div>

              </div>
            </div>
          )
        })}
      </div>

      <Separator size={50} />

      {selectedItems && selectedItems.length > 0 && <>
        <ReactDataGrid
          idProperty="id"
          columns={gridColumns}
          dataSource={selectedItems}
          style={gridStyle}
          pagination={false}
          defaultFilterValue={gridFilter}
          enableSelection
          // onSelectionChange={(selection: any) => {
          //   if (selection.data.length === 0) {
          //     setSelectedPart({});
          //     return;
          //   }
          //   setSelectedPart(selection.data);
          // }}
        />
      </>}

    </>}
  </>
};

export default TreeView;