import axios from 'axios';

export const createPart = async (payload: any) => {
  return await axios.post("parts", payload).then((response: any) => response.data).catch((err: any) => err);
}

export const checkIsPartNumberAvailable = async (payload: { id: string, partNumber: string }) => {
  return await axios.post("/parts/isPartNumberAvailable", payload).then((response: any) => response.data).catch((err: any) => err);
}

export const getParts = async () => {
  return await axios.get("parts").then((response: any) => response.data).catch((err: any) => err);
}

export const getPartRevisions = async (partId: string) => {
  return await axios.get(`parts/${partId}/revisions`).then((response: any) => response.data).catch((err: any) => err);
}

export const updatePart = async (partId: string, payload: any) => {
  return await axios.patch(`parts/${partId}`, payload).then((response: any) => response.data).catch((err: any) => err);
}

export const deletePart = async (partId: string) => {
  return await axios.delete(`parts/${partId}`).then((response: any) => response.data).catch((err: any) => err);
}
