import { AlertContext, ConfirmContext, Loading, Separator, truncateString } from "@aptitude/aptitudehelpers";
import { useContext, useEffect, useState } from "react";
import { createDocument, getDocumentsByPartId, removeDocument, toggleDocumentAsCurrent } from "../../services/documents.service";
import { getDocumentTypes } from "../../services/settings.service";

const Documents = (props: any) => {
  const { part } = props;
  const {setAlert} = useContext(AlertContext);
  const {setConfirm} = useContext(ConfirmContext);

  useEffect(() => {
    _getDocuments();
    _getDocumentTypes();
  }, []);

  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [documents, setDocuments] = useState([] as any);
  const [documentsImmutable, setDocumentsImmutable] = useState([] as any);
  const _getDocuments = async () => {
    setLoadingDocuments(true);
    await getDocumentsByPartId(part.id).then((response: any) => {
      if (response.message === "OK") {
        setDocuments(response.documents);
        setDocumentsImmutable(response.documents);
        setLoadingDocuments(false);
        return;
      }

      setLoadingDocuments(false);
      setAlert({ type: "danger", message: "Error loading documents" });
    }).catch((error: any) => {
      setLoadingDocuments(false);
      setAlert({ type: "danger", message: "Error loading documents" });
    })
  };

  const [loadingGetDocumentTypes, setLoadingGetDocumentTypes] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([] as any);
  const _getDocumentTypes = async () => {
    setLoadingGetDocumentTypes(true);
    await getDocumentTypes().then((response: any) => {
      if (response.message === "OK") {
        setDocumentTypes(response.documentTypes.types);
        setLoadingGetDocumentTypes(false);
        return;
      }

      setAlert({ type: "danger", message: "Error loading document types" });
      setLoadingGetDocumentTypes(false);
    }).catch((error: any) => {
      console.log(error);
      setAlert({ type: "danger", message: "Error loading document types" });
      setLoadingGetDocumentTypes(false);
    });
  };

  const toggleCurrent = async (docId: string) => {
    let updatedDocuments = documents.map((doc: any) => {
      if (doc.id === docId) {
        return { ...doc, isCurrent: !doc.isCurrent };
      }
      return doc;
    });

    setDocuments(updatedDocuments);

    await toggleDocumentAsCurrent(part.id, docId).then((response: any) => {
      if (response.message !== "OK") {
        setAlert({ type: "danger", message: "Error on marking as current" });
        return;
      }
      setAlert({ type: "success", message: "Document marked as current" });
    });
  };

  const [loadingAddDocument, setLoadingAddDocument] = useState(false);
  const [newDocument, setNewDocument] = useState({ documentUrl: "", description: "", documentType: "", isCurrent: false });
  const addNewDocument = async () => {
    setLoadingAddDocument(true);
    await createDocument(part.id, newDocument).then((response: any) => {
      if (response.message === "OK") {
        setAlert({ type: "success", message: "Document added successfully" });
        setNewDocument({ documentUrl: "", description: "", documentType: "", isCurrent: false });

        let updatedDocuments = documents;
        updatedDocuments.push({
          id: response.newDocId,
          documentUrl: newDocument.documentUrl,
          description: newDocument.description,
          documentType: newDocument.documentType,
          isCurrent: newDocument.isCurrent,
        });

        setDocuments(updatedDocuments);
        setDocumentsImmutable(updatedDocuments);

        setLoadingAddDocument(false);
        return;
      }

      setAlert({ type: "danger", message: "Error adding document" });
      setLoadingAddDocument(false);
    }).catch((error: any) => {
      console.log(error);
      setAlert({ type: "danger", message: "Error adding document" });
      setLoadingAddDocument(false);
    });
  };

  const [loadingRemoveDocument, setLoadingRemoveDocument] = useState(false);
  const _removeDocument = async (docId: string) => {
    setLoadingRemoveDocument(true);
    await removeDocument(part.id, docId).then((response: any) => {
      if (response.message === "OK") {
        setAlert({ type: "success", message: "Document removed successfully" });
        let updatedDocuments = documents.filter((doc: any) => doc.id !== docId);
        setDocuments(updatedDocuments);
        setLoadingRemoveDocument(false);
        return;
      }

      setAlert({ type: "danger", message: "Error removing document" });
      setLoadingRemoveDocument(false);
    }).catch((error: any) => {
      setAlert({ type: "danger", message: "Error removing document" });
      setLoadingRemoveDocument(false);
    });
  };

  return <>
    <Loading loading={loadingDocuments} />

    {!loadingDocuments && <>
      <Separator size={10} />

      <div className="row">
        <div className="col-4">

          <select
            className="form-control"
            onChange={(e: any) => {
              let filter = e.target.value;
              if (filter === "") {
                _getDocuments();
                return;
              }

              let filteredDocuments = documentsImmutable.filter((doc: any) => doc.documentType === filter);
              setDocuments(filteredDocuments);
            }}>
            <option value="">All Types</option>
            {!loadingGetDocumentTypes && documentTypes.length > 0 && documentTypes.map((type: any, index: number) => {
              return <option key={`document-type-${index}`} value={type}>{type}</option>
            })}
          </select>

        </div>
      </div>

      <Separator size={15} />

      {documents.length === 0 && <>No documents</>}

      {documents.length > 0 && <>
        <small className="text-muted">
          <div className="row">
            <div className="col">
              {documents.length} document{documents.length > 1 ? "s" : ""}
            </div>
            <div className="col text-end">
              (click to toggle current)
            </div>
          </div>
        </small>

        <Separator size={15} />

        <ul className="list-group">
          {documents.map((doc: any, index: number) => {
            return <li key={`document-${index}`} className="list-group-item">
              <div className="row">
                <div className="col-1">

                  <i
                  onClick={() => {
                    setConfirm({
                      type: "danger",
                      title: "Remove Document",
                      message: "Are you sure you want to remove this document?",
                      buttonLabel: "Remove",
                      onConfirm: () => _removeDocument(doc.id),
                    })
                  }}
                  className="fas fa-minus-circle me-3 text-danger cursor-pointer"></i>

                </div>
                <div className="col-1 ps-0">

                  <div className="badge bg-secondary">{doc.documentType}</div>

                </div>
                <div className="col">

                  <a href={doc.documentUrl} target="_blank" rel="noopener noreferrer">
                    {truncateString(doc.documentUrl, 70, true, "center")}
                  </a> <i className="fas fa-external-link-alt ms-2 text-muted"></i>

                  <Separator size={5} />

                  <small className="text-muted">{truncateString(doc.description || "", 100)}</small>

                </div>
                <div className="col-2 text-end">

                  {doc.isCurrent && <div className="badge bg-primary cursor-pointer" onClick={() => toggleCurrent(doc.id)}>current</div>}
                  {!doc.isCurrent && <div className="badge bg-secondary cursor-pointer" onClick={() => toggleCurrent(doc.id)}>not current</div>}

                </div>
              </div>

            </li>
          })}
        </ul>
      </>}
    </>}



    {!loadingDocuments && <>
      <Separator size={30} />

      <strong>Add new document</strong>

      <Separator size={10} />

      <div className="row align-items-center">
        <div className="col-7">

          <div className="form-group">
            <input
              type="url"
              className="form-control"
              name="documentUrl"
              placeholder="Document URL"
              disabled={loadingAddDocument}
              onChange={(e: any) => {
                setNewDocument({ ...newDocument, documentUrl: e.target.value });
              }} />
          </div>

          <Separator size={10} />

          <div className="row">
            <div className="col">

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="description"
                  placeholder="Document Description (optional)"
                  disabled={loadingAddDocument}
                  onChange={(e: any) => {
                    setNewDocument({ ...newDocument, description: e.target.value });
                  }} />
              </div>

            </div>
            <div className="col">

              <div className="form-group">
                <select
                  className="form-control"
                  name="documentType"
                  disabled={loadingAddDocument}
                  onChange={(e: any) => {
                    setNewDocument({ ...newDocument, documentType: e.target.value });
                  }}>
                  <option value="">Select Document Type</option>
                  {!loadingGetDocumentTypes && documentTypes.length > 0 && documentTypes.map((type: any, index: number) => {
                    return <option key={`document-type-${index}`} value={type}>{type}</option>
                  })}
                </select>
              </div>

            </div>
          </div>

        </div>
        <div className="col">
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                name="isCurrent"
                disabled={loadingAddDocument}
                className="form-check-input me-2"
                onChange={(e: any) => {
                  setNewDocument({ ...newDocument, isCurrent: e.target.checked });
                }} /> Is <strong>Current Version</strong>?
            </label>
          </div>
        </div>
        <div className="col text-end">
          <button
            type="button"
            disabled={loadingAddDocument}
            onClick={() => addNewDocument()}
            className="btn btn-primary">
              <Loading loading={loadingAddDocument} parent="inline" color="color-white" />
              {!loadingAddDocument && <i className="fas fa-check me-2"></i>} Add
          </button>
        </div>
      </div>
    </>}
  </>;
};

export default Documents;