import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { AlertProvider, ConfirmProvider } from "@aptitude/aptitudehelpers/build";
import { DataProvider } from './context/Data.context';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import App from './App';
import Parts from './views/Parts/Parts.component';
import Templates from './views/Templates/Templates.component';
import Projects from './views/Projects/Projects.component';
import Materials from './views/Materials/Materials.component';
import TreeView from './components/TreeView.component';
import ImportCSV from './views/ImportCSV/ImportCSV.view';

import './index.scss';

const routes: any = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/templates',
        element: <Templates />,
      },
      {
        path: '/projects',
        element: <Projects />,
      },
      {
        path: '/materials',
        element: <Materials />,
      },
      {
        path: '/parts',
        element: <Parts />,
      },
      {
        path: '/parts/import',
        element: <ImportCSV />,
      },
      {
        path: '/treeview',
        element: <TreeView />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <DataProvider>
      <ConfirmProvider>
        <AlertProvider>
          <RouterProvider router={routes} />
        </AlertProvider>
      </ConfirmProvider>
    </DataProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
