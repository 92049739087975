import { createContext, useState } from "react";

export const DataContext = createContext<any>(null);

export const DataProvider = ({ children }: any) => {
  const defaultData: any = {};

  const [data, setData] = useState(defaultData as any);

  return <DataContext.Provider value={{data, setData}}>{children}</DataContext.Provider>
};